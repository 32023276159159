import React, {useEffect} from 'react';
import './Register.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store";
import {Redirect} from "react-router-dom";
import {RegisterForm} from "./registerForm/RegisterForm";
import {commonActions} from "../../../redux/reducers/commonReducer";

export const Register = () => {
    const dispatch = useDispatch()
    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)
    useEffect(() => {
        isAuth && dispatch(commonActions.setIsShowModalAC(false))
    },[dispatch,isAuth])
    if (isAuth) {
        return <Redirect to={'/'}/>
    }
    return (
            <div className={'register-modal-content'}>
                <RegisterForm/>
            </div>
    );
};

