import React from 'react'
import './BasketColumns.scss'
import {COLUMNS} from "../../index";

export const BasketColumns = () => {
    return (
            <div className={'basket-columns-title'}>
                <div className={'basket-column-title'}>
                    {COLUMNS.PRICE}
                </div>
                <div className={'basket-column-title'}>
                    {COLUMNS.COUNT}
                </div>
                <div className={'basket-column-title'}>
                    {COLUMNS.AMOUNT}
                </div>
            </div>
    )
}
