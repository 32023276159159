//TODO сделать так, что бы на проде, qa и локальном компе картинки отображались. Может с каким то профилем билдить приложение, в зависимости от окружения.
export const config = {
    baseImgPathUrl: ''
    //baseImgPathUrl: 'http://shop-qa.republic-club.by'
}

export const googleConfig = {
    iframeURL: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2350.3477995881753!2d27.4565378161274!3d53.907795280100615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dbc52d3992efe1%3A0x79449566f113b650!2sRE%3APUBLIC!5e0!3m2!1sen!2sus!4v1518651947670',
    captchaURL: 'https://www.google.com/recaptcha/api.js',
    captchaSiteKey: '6LetEAUbAAAAAEYzrSTN3-mT_tLZF2ju_VBAjS1w',
}
