import React from 'react';
import './BurgerIcon.scss'
import {BURGER_MENU_STATUS} from "../constants";

interface IProps {
    changeBurgerStatus: () => void
    burgerStatus: BURGER_MENU_STATUS
}

export const BurgerIcon = ({changeBurgerStatus, burgerStatus}:IProps) => {
    return (
            <div className={`burger-menu-icon ${burgerStatus}`}
                 role="button"
                 onClick={changeBurgerStatus}
            >
                <i className={burgerStatus}/>
                <i className={burgerStatus}/>
                <i className={burgerStatus}/>
            </div>
    );
};
