import React, {useEffect} from 'react'
import './Item.scss'
import {ItemSettings} from './itemSettings/ItemSettings'
import {useDispatch, useSelector} from "react-redux"
import {RootStateType} from "../../redux/store"
import {
    getDataForItemTC,
    IItemInitialState,
    itemsActions,
    setCurrentItemSizes
} from "../../redux/reducers/items-reducer"
import {useHistory, useParams} from "react-router-dom"
import {addItemToCartTC} from "../../redux/reducers/cartReducer"
import {ItemImages} from "./images/ItemImages"
import {commonActions} from "../../redux/reducers/commonReducer"
import { IItem } from '../../types/items'
import {IItemPrice} from "../../types/price";

export const Item = () => {
    const history = useHistory()
    const {itemUrlId}: any = useParams()
    const itemId = +itemUrlId
    const dispatch = useDispatch()

    const prices = useSelector<RootStateType, IItemPrice[]>(state => state.prices.prices)
    const {item, uniqItemSizes, currentColor, currentSize, allImages, filteredImages, currentImage, currentColorName, uniqItemColours}
            = useSelector<RootStateType, IItemInitialState>(state => state.items)
    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)

    useEffect(() => {
        dispatch(getDataForItemTC(itemId))
        return () => {
            dispatch(itemsActions.setCurrentColorAC(null))
            dispatch(itemsActions.setCurrentSizeAC(null))
            dispatch(itemsActions.setAllImagesForItemAC(null))
            dispatch(itemsActions.setItemAC({} as IItem))
            dispatch(itemsActions.setUniqItemColorsAC([]))
            dispatch(itemsActions.setUniqItemSizesAC([]))
        }
    }, [dispatch, itemId])

    const addBasket = () => {
        if (!isAuth) {
            dispatch(commonActions.setWarningToastAC('Для того что бы добавить товар в корзину, нужно войти в аккаунт'))
            return history.push('/login')
        }else if (!currentItemPrice) {
            dispatch(commonActions.setWarningToastAC('Выберите размер и цвет'))
        } else {
            dispatch(addItemToCartTC(currentItemPrice.itemPriceId))
            dispatch(commonActions.setSuccessToastAC('Товар добавлен в корзину'))
        }
    }
    const changeCurrentImageHandler = (imagePath: string) => {
        dispatch(itemsActions.setCurrentImageAC(imagePath))
    }
    const changeCurrentColorHandler = (colorId: number) => {
        dispatch(setCurrentItemSizes(colorId))
    }
    const changeCurrentSizeHandler = (sizeId: number) => {
        dispatch(itemsActions.setCurrentSizeAC(sizeId))
    }
    const currentItemPrice = prices.find(p => {
        return p.colourId === currentColor && p.sizeId === currentSize
    })

    return (
            <div className={'item'}>
                    <ItemImages currentImage={currentImage} currentColor={currentColor}
                                            filteredImages={filteredImages}
                                            allImages={allImages}
                                            changeCurrentImageHandler={changeCurrentImageHandler}
                    />
                    <ItemSettings addBasket={addBasket}
                                  item={item}
                                  uniqItemSizes={uniqItemSizes}
                                  currentSize={currentSize}
                                  changeCurrentColorHandler={changeCurrentColorHandler}
                                  changeCurrentSizeHandler={changeCurrentSizeHandler}
                                  currentColor={currentColor}
                                  currentColorName={currentColorName}
                                  uniqItemColours={uniqItemColours}
                                  currentItemPrice={currentItemPrice}/>
            </div>
    )
}
