import React, {useEffect} from "react";
import './Basket.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {getCartsTC, ICartReducer} from "../../redux/reducers/cartReducer";
import {BackComponent} from "../../components/backComponent/BackComponent";
import {Title} from "../../components/title/Title";
import {Layout} from "./Layout";
import {getAddressesTC, IAddressReducer} from "../../redux/reducers/addressReducer";
import {createOrderTC} from "../../redux/reducers/orderReducer";
import {commonActions} from "../../redux/reducers/commonReducer";
import {MODAL_WINDOWS} from "../../components/modalWindow/ModalWindow";
import {TEXT} from "./index";
import {useHistory} from "react-router-dom";

export const Basket = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {carts, totalAmount, totalCount} = useSelector<RootStateType, ICartReducer>(state => state.carts)
    const {
        defaultAddress,
        currentAddress,
        currentAddressId
    } = useSelector<RootStateType, IAddressReducer>(state => state.address)

    useEffect(() => {
        dispatch(getAddressesTC())
        dispatch(getCartsTC("CHOOSE"))
    }, [dispatch, totalCount])

    const showChangeAddressHandler = () => {
        dispatch(commonActions.setIsShowModalAC(true, MODAL_WINDOWS.CHANGE_ADDRESS))
    }
    const showAddAddressHandler = () => {
        dispatch(commonActions.setIsShowModalAC(true, MODAL_WINDOWS.ADD_ADDRESS))
    }

    const createOrder = () => {
        dispatch(createOrderTC(currentAddressId, history))
    }

    return (
            <div className={'basket'}>
                <div className={'basket-container'}>
                    <BackComponent/>
                    {
                        carts.length > 0
                                ? <Layout
                                        totalCount={totalCount}
                                        carts={carts}
                                        defaultAddress={defaultAddress}
                                        currentAddress={currentAddress}
                                        totalAmount={totalAmount}
                                        createOrder={createOrder}
                                        showChangeAddressHandler={showChangeAddressHandler}
                                        showAddAddressHandler={showAddAddressHandler}
                                />
                                : <div className={'empty-basket-content'}>
                                    <Title title={TEXT.TITLE}/>
                                    <div>{TEXT.EMPTY_CART}</div>
                                </div>
                    }
                </div>

            </div>
    )
}
