import React from 'react';
import './RepublicLogo.scss'
import republicLogo from '../../assest/images/rc.svg'

export const RepublicLogo = () => {
    return (
            <a href={'https://republic-club.by/'}>
                <img alt={'republicLogo'}
                     className={'republic-logo'}
                     src={republicLogo}/>
            </a>
    );
};
