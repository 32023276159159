import React from 'react'
import './ModalWindow.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {commonActions, ICommonReducer} from "../../redux/reducers/commonReducer";
import {AddAddress, ChangeUserInfo, ChangeAddress} from "../modalComponents";
import {Login, Register} from "../../pages/auth";
import {useHistory, useLocation} from "react-router-dom";
import {Title} from "../title/Title";

export enum MODAL_WINDOWS {
    CHANGE_ADDRESS = "Изменить адрес доставки",
    ADD_ADDRESS = "Добавить новый адрес доставки",
    CHANGE_USER_INFO = "Изменение личной информации",
    LOGIN = "Вход в кабинет покупателя!",
    REGISTER = 'Добро пожаловать!',
}

export const ModalWindow = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<any>();

    const {isShowModal, componentName} = useSelector<RootStateType, ICommonReducer>(state => state.common)

    const closeChangeAddressModal = () => {
        dispatch(commonActions.setIsShowModalAC(false))
        if (componentName === MODAL_WINDOWS.REGISTER && location.state?.fromLogin) {
            history.go(-2)
        } else if (componentName === MODAL_WINDOWS.LOGIN || componentName ===MODAL_WINDOWS.REGISTER) {
            history.goBack()
        }
    }

    if (!isShowModal) {
        return null
    }
    return (
            <div onClick={(event => event.stopPropagation())} className={'modal-container'}>
                <div className={'container'}>
                    <div className={'modal'}>
                        <div className={'modal-body'}>
                            <Title title={componentName || ""} />
                            <button className={'close-modal-button'} onClick={closeChangeAddressModal}>
                                <span className={'close-modal-row'}/>
                                <span className={'close-modal-row'}/>
                            </button>
                            {componentName === MODAL_WINDOWS.CHANGE_ADDRESS && <ChangeAddress/>}
                            {componentName === MODAL_WINDOWS.ADD_ADDRESS && <AddAddress/>}
                            {componentName === MODAL_WINDOWS.CHANGE_USER_INFO && <ChangeUserInfo/>}
                            {componentName === MODAL_WINDOWS.LOGIN && <Login/>}
                            {componentName === MODAL_WINDOWS.REGISTER && <Register/>}
                        </div>

                    </div>
                </div>
            </div>
    )
}
