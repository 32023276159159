import {coloursAPI} from "../../api/shop/colorAPI";
import { IColour } from "../../types/colour";
import {BaseThunkType, InferActionsType} from "../store";
import {commonThunkCreator} from "./commonReducer";

type ThunkType = BaseThunkType<IActions>;
export type IActions = InferActionsType<typeof colourActions>;
export type IColourReducer = typeof initialState;

const initialState = {
    colours: [] as IColour[],
    colour: '',
    id: 0,
    colourName: ''
}

export const colourReducer = (state = initialState, action: IActions): IColourReducer => {
    switch (action.type) {
        case "ADMIN/SET-COLOURS":
            return {
                ...state,
                colours: action.payload.colours
            }

        default:
            return state
    }
}
export const colourActions = {
    setColoursAC: (colours: IColour[]) =>
        ({
            type: "ADMIN/SET-COLOURS",
            payload: {colours}
        } as const),
};

export const getColoursTC = (categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const colours = await coloursAPI.getColours(categoryId)
        dispatch(colourActions.setColoursAC(colours.data.content))
    }, dispatch);
};
