import React from 'react';
import './MenuLinks.scss'
import {NavLink} from "react-router-dom";

export const MenuLinks = () => {
    return (
            <div className={'menu-links'}>
                <NavLink exact to={'/'} className={'menu-link'}
                         activeClassName={'menu-active-link'}>ГЛАВНАЯ</NavLink>
                <NavLink to={'/rules-pay'} className={'menu-link'}
                         activeClassName={'menu-active-link'}>ПРАВИЛА ОПЛАТЫ</NavLink>
                <NavLink to={'/about'} className={'menu-link'}
                         activeClassName={'menu-active-link'}>О НАС</NavLink>
                <NavLink to={'/public-offer'} className={'menu-link'}
                         activeClassName={'menu-active-link'}>
                    ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ
                </NavLink>
            </div>
    );
};
