import React from 'react'
import './CurrentAddress.scss'
import {IAddress} from "../../types/address";

interface IProps {
    currentAddress: null | IAddress,
    defaultAddress: null | IAddress,
}

export const CurrentAddress = ({currentAddress, defaultAddress}: IProps) => {
    return (
            <div className={'basket-current-address'}>
                {currentAddress ? currentAddress?.country && currentAddress?.country + ',' : defaultAddress?.country && defaultAddress?.country + ','}
                {currentAddress ? currentAddress?.state && currentAddress?.state + ',' : defaultAddress?.state && defaultAddress?.state + ','}
                {currentAddress ? currentAddress?.city && currentAddress?.city + ',' : defaultAddress?.city && defaultAddress?.city + ','}
                {currentAddress ? currentAddress?.description && currentAddress?.description : defaultAddress?.description && defaultAddress?.description}
            </div>
    )
}
