import {instance} from '../instance'


export const orderAPI = {
    getOrders() {
        return instance.get(`order?size=999`)
    },
    getOrder(orderId: number) {
        return instance.get(`order/${orderId}`)
    },
    createOrder(addressId: number) {
        return instance.post(`order?addressId=${addressId}`)
    }
}
