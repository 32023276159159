import React from 'react'
import './SlideImages.scss'
import {CarouselProvider, Slider} from "pure-react-carousel"
import {UseWindowDimensions} from "../../../../hooks/useWindowDimensions";
import {SmallImage} from "./smallImage/SmallImage";
import {IPhotos} from "../../../../types/items";

interface IProps {
    currentColor: number | null
    filteredImages: IPhotos[]
    allImages: IPhotos[]
    changeCurrentImageHandler: (value: string) => void
}

export const SlideImages = ({currentColor, filteredImages, allImages, changeCurrentImageHandler}: IProps) => {
    const windowDimensions = UseWindowDimensions()
    const currentArray = currentColor ? filteredImages : allImages
    return (
            <CarouselProvider
                    infinite={true}
                    visibleSlides={windowDimensions.width < 690 ? 2 : 5}
                    naturalSlideWidth={90}
                    naturalSlideHeight={100}
                    totalSlides={currentArray.length}
                    orientation={windowDimensions.width < 690 ? "horizontal" : "vertical"}
            >
                <Slider className={'small-images-container'}>
                    {currentArray.map((item, index) =>
                            <SmallImage item={item}
                                        changeCurrentImageHandler={changeCurrentImageHandler}
                                        key={index}
                                        index={index}
                            />
                    )}
                </Slider>
            </CarouselProvider>
    )
}
