import React, {useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import {Header} from './pages/header/Header';
import {Footer} from './pages/footer/Footer';
import {Preloader, ModalWindow} from './components';
import {Routes} from './navigation/Routes';
import ThemeProvider from "./utils/themeProvider/ThemeProvider";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "./redux/store";
import {authMeTC} from "./redux/reducers/authReducer";

const App = () => {
    const dispatch = useDispatch();
    const isInitialized = useSelector<RootStateType, boolean>(state => state.auth.isInitialized)

    useEffect(() => {
        dispatch(authMeTC())
    }, [dispatch])

    if(!isInitialized){
        return <Preloader />
    }
    return (
            <ThemeProvider>
                <div className={'app'}>
                    <Preloader/>
                    <ToastContainer
                            style={{zIndex: 10000000000}}
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                    />
                    <ModalWindow/>
                    <div className={'main'}>
                        <div className={'container'}>
                            <Header/>
                            <Routes/>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
    );
}

export default App;
