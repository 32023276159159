import React from 'react'
import './OrderRow.scss'
import {NavLink} from 'react-router-dom';
import {dateFormat} from "../../../../utils/dateUtils";
import {IOrder} from "../../../../types/order";

interface IProps {
    item: IOrder
}

export const OrderRow = ({item}: IProps) => {
    return (
            <NavLink to={`/order/${item.id}`} className={'order-list-row'}>
                <div className={'order-list-row-title'}>
                    {item.id}
                </div>
                <div className={'order-list-row-title'}>
                    {dateFormat(item.createdDate)}
                </div>
                <div className={'order-list-row-title'}>
                    {`${item.amount}BYN`}
                </div>
                <div className={'order-list-row-title'}>
                    <div className={'order-list-row-title'}>{item.address.city}</div>
                    <div className={'order-list-row-title'}>{item.address.description}</div>
                </div>
            </NavLink>
    )
}
