import React from 'react'
import './BlackButton.scss'
import {NavLink} from "react-router-dom";

interface IProps {
    title: string
    type: "button" | "submit" | "reset" | undefined
    onClick?: () => void
    isLink?: boolean
    to?: string
    target?: string
}

export const BlackButton = ({title, type, onClick, isLink = false, to, target}: IProps) => {
    return (
            <>
                {
                    isLink
                            ? <NavLink className={'black-button'} target={target} to={`/${to}`}>{title}</NavLink>
                            : <button className={'black-button'} type={type} onClick={onClick}>{title}</button>
                }

            </>

    )
}
