import React from 'react'
import './StaticsPage.scss'

export const PublicOffer = () => {
    return (
            <div className={'page'}>
                <div className={'container'}>
                    <h2 className={'title'}>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ</h2>
                    <div className={'paragraph'}>
                        на приобретение товаров и услуг через интернет-магазин www.republic-club.by
                    </div>

                    <div className={'paragraphTitle'}>
                        1. Термины и определения.
                    </div>
                    <div className={'paragraph'}>
                        В настоящей оферте, если контекст не требует иного, нижеприведенные термины имеют следующие
                        значения:
                        <br/>
                        <span className={'bold'}>Продавец</span> – Индивидуальный предприниматель Старцев Андрей
                        Валентинович, почтовый адрес - г.
                        Минск, ул. Притыцкого, 62, Республика Беларусь, 220140. Сокращенное наименование – ИП Старцев
                        А.В..
                        <br/>
                        <span className={'bold'}>Покупатель</span> - физическое лицо, заключившее с Продавцом настоящий
                        договор оферты на условиях,
                        содержащихся в нем, заказывающее, приобретающее товар (работу, услугу) или использующее товар
                        (результат работы, услугу) исключительно для личных, семейных, домашних и иных нужд, не
                        связанных с осуществлением предпринимательской деятельности, либо указанное (упомянутое) при
                        оформлении Заказа в качестве Покупателя.
                        <br/>
                        <span className={'bold'}>Стороны</span> – указание на Продавца и Покупателя при совместном
                        упоминании в настоящем Договоре.
                        <br/>
                        <span className={'bold'}>Интернет-магазин</span> - информационный ресурс, размещенный Продавцом
                        в сети Интернет по адресу
                        www.republic-club.by, позволяющий осуществить заказ на приобретение или выбор и приобретение
                        товаров без (вне) торгового объекта.
                        <br/>
                        <span className={'bold'}>Товар</span> - объект купли-продажи (перечень наименований
                        ассортимента), представленный к продаже в
                        Интернет-магазине и возможный к формированию Заказа.
                        <br/>
                        <span className={'bold'}>Заказ</span> - отдельные позиции из ассортиментного перечня Товаров,
                        указанные Покупателем при
                        оформлении Заказа на сайте www.republic-club.by.
                        <br/>
                        <span className={'bold'}>Оферта</span> - публичное предложение Продавца, адресованное любому
                        физическому лицу, заключить с ним
                        договор купли-продажи на условиях, содержащихся в настоящем Договоре.
                        <br/>
                        <span className={'bold'}>Акцепт</span> - полное и безоговорочное принятие Покупателем условий
                        настоящего Договора.
                        <br/>
                        <span className={'bold'}>Доставка</span> - передача Товара Покупателю Продавцом одним из
                        способов, перечисленных на страницах
                        Интернет-магазина и выбранных Покупателем при оформлении Заказа.
                    </div>


                    <div className={'paragraphTitle'}>
                        2. Общие положения. Момент заключения Договора.
                    </div>
                    <div className={'paragraph'}>
                        2.1. Настоящий Договор, размещенный на сайте www.republic-club.by, в соответствии с п.2 ст.407
                        Гражданского кодекса Республики Беларусь (далее – ГК РБ) является публичной офертой Продавца,
                        адресованной неопределенному кругу физических лиц, заключить договор купли-продажи Товара на
                        условиях, определенных в настоящем Договоре, и содержит все существенные условия купли-продажи
                        Товара по образцам через Интернет-магазин.
                        <br/>
                        2.2. Заключение Договора происходит посредством присоединения Покупателя к настоящему Договору,
                        т.е. посредством принятия (акцепта) условий настоящего Договора в целом без каких-либо условий,
                        изъятий и оговорок (ст.398 ГК РБ).
                        <br/>
                        2.3. Акцептом настоящей оферты признается оплата Покупателем Заказа в соответствии с условиями
                        настоящего Договора, что является равносильным заключению договора на условиях, изложенных в
                        оферте.
                        <br/>
                        2.4. Настоящий Договор при условии соблюдения порядка его принятия (акцепта) считается
                        заключенным в простой письменной форме (п.п. 2,3 ст.404, п.3 ст.408 ГК РБ).
                        <br/>
                        2.5. Продавец оставляет за собой право вносить изменения в настоящий Договор и в информацию на
                        страницах Интернет-магазина, в связи с чем, Покупатель обязуется на момент оформления Заказа
                        ознакомиться с текстом настоящего договора и информацией, размещенной на страницах
                        Интернет-магазина
                        <br/>
                        2.6. Покупатель, производящий акцепт настоящей оферты, принимает и соглашается со всеми
                        условиями, изложенными в настоящем Договоре, и с информацией, размещенной на страницах
                        Интернет-магазина в момент оформления Заказа.
                        <br/>
                        2.7. Каждая Сторона гарантирует другой Стороне, что обладает соответствующим правом и
                        достаточным объемом дееспособности, а равно всеми иными правами и полномочиями, необходимыми для
                        заключения и исполнения настоящего Договора.
                        <br/>
                        2.8. На основании вышеизложенного, внимательно ознакомьтесь с текстом публичной оферты, и если
                        Вы не согласны с каким-либо пунктом оферты, Вам предлагается отказаться от покупки Товаров и
                        (или) использования Услуг, предоставляемых Продавцом.
                    </div>
                    <div className={'paragraphTitle'}>
                        3. Предмет Договора и цена Товара.
                    </div>
                    <div className={'paragraph'}>
                        3.1. Продавец продает, а Покупатель принимает и оплачивает Товар в соответствии с условиями
                        настоящего Договора.
                        <br/>
                        3.2. Право собственности на заказанные Товары, а также риск случайной гибели или повреждения
                        Товара переходят к Покупателю с момента фактической передачи Товара Покупателю.
                        <br/>
                        3.3. Цены на Товар определяются Продавцом и указываются на страницах Интернет-магазина.
                        <br/>
                        3.4. Цена на каждую позицию товара указывается в белорусских рублях.
                        <br/>
                        3.5. Цена Товара может быть изменена Продавцом в одностороннем порядке. При этом цена на Товар,
                        на который оформлен Заказ и/или Покупатель произвел предварительную оплату в порядке, сроке и на
                        условиях, определенных настоящим Договором, изменению не подлежит.
                        <br/>
                        3.6. Продавец вправе предоставлять Покупателю скидки на Товары и устанавливать систему бонусов.
                        Виды скидок, порядок и условия начисления и предоставления бонусов указываются на страницах
                        Интернет-магазина в публичном доступе и могут быть изменены Продавцом в одностороннем порядке.
                    </div>
                    <div className={'paragraphTitle'}>
                        4. Оформление и отмена заказа. Регистрация на сайте
                    </div>
                    <div className={'paragraph'}>
                        4.1. Покупатель самостоятельно оформляет Заказ на Товар путем заполнения электронной формы
                        Заказа, размещенной в Интернет-магазине, и отправляет сформированный Заказ Продавцу посредством
                        сети Интернет.
                        <br/>
                        4.2. После оформления Заказа Продавец в течение двух рабочих дней обрабатывает Заказ
                        (согласовывает с Покупателем по контактному телефону или электронной почте содержащуюся в Заказе
                        информацию с целью ее уточнения и проверки правильности оформления Заказа) и высылает Покупателю
                        подтверждение Заказа в электронном виде посредством сети Интернет по указанному при регистрации
                        (оформлении Заказа) адресу.
                        <br/>
                        4.3. Все информационные материалы, представленные на страницах Интернет-магазина, носят
                        справочный характер и не могут в полной мере передавать полную информацию об определенных
                        свойствах и характеристиках Товара, таких как, например: оттенок и структура тканей и др. В
                        случае возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед
                        оформлением Заказа Покупатель должен обратиться за консультацией к Продавцу по телефонам,
                        указанным в разделе «Контакты» Интернет-магазина, послать запрос на адрес электронной почты
                        Продавца, в онлайн чат Продавца на сайте либо воспользоваться формой «Обратная связь».
                        <br/>
                        4.4. Публикация информации, очевидно не соответствующей Товару, в том числе цены, фото и
                        описания Товара, признается технической ошибкой.
                        <br/>
                        4.5. При регистрации (оформлении Заказа) в Интернет-магазине Покупатель представляет следующую
                        регистрационную информацию о себе: фамилия, имя, отчество (на русском языке); фактический адрес
                        доставки; адрес электронной почты; контактные телефоны и иную информацию, указанную в
                        регистрационной форме.
                        <br/>
                        4.6. Покупатель выражает согласие и разрешает Продавцу собирать, обрабатывать и хранить свои
                        персональные данные, указанные при регистрации (оформлении Заказа), в целях осуществления
                        доставки заказанного Товара и исполнения условий настоящего Договора.
                        <br/>
                        4.7. Покупатель, зарегистрировавшийся в Интернет-магазине, может получать индивидуальную
                        идентификацию путем предоставления логина и пароля. Индивидуальная идентификация Покупателя
                        позволяет избежать несанкционированных действий третьих лиц от имени Покупателя и открывает
                        доступ к дополнительным сервисам. Передача Покупателем логина и пароля третьим лицам запрещена.
                        4.8. Продавец обязуется не сообщать персональные данные Покупателя лицам, не имеющим отношения к
                        исполнению Заказа.
                        <br/>
                        4.9. Покупатель самостоятельно несёт ответственность за содержание и достоверность информации,
                        предоставленной при осуществлении регистрации, оформлении Заказа.
                        <br/>
                        4.10. Оформлением Заказа Покупатель подтверждает, что до оформления Заказа ему предоставлена, а
                        он самостоятельно полностью ознакомился на страницах Интернет-магазина, с необходимой и
                        достоверной информацией о приобретаемом Товаре, а также о сопутствующих работах (услугах),
                        оказываемых Продавцом, в том числе информацией, содержащей:
                        <br/>
                        <span className={'listItem'}>
                        • наименование (фирменное наименование) Продавца, место его нахождения, режим работы;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •наименование товара;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •сведения об основных потребительских свойствах товара;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •цены и условия оплаты товара;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •сроки службы товара, указание условий его хранения, а также сведения о необходимых действиях
                        Покупателя по истечении указанных сроков службы и возможных последствиях при невыполнении таких
                        действий;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •комплектность товара;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •сведения о материалах, из которых изготовлен товар;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •сведения о цвете, размере товара;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •необходимые сведения о правилах и условиях эффективного и безопасного использования товара, в
                        том числе ухода за ним;
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •виды и особенности предлагаемых Продавцом работ (услуг);
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •сведения о сроках передачи Товара, цене и условиях оплаты доставки товара.
                        </span>
                        <br/>
                        4.11. Оформлением Заказа Покупатель подтверждает приобретение им Товара для использования, не
                        связанного с предпринимательской деятельностью.
                    </div>
                    <div className={'paragraphTitle'}>
                        5. Оплата Заказа.
                    </div>
                    <div className={'paragraph'}>
                        5.1. Оплата Заказа Покупателем производится на условиях и в сроки, указанные на платежной
                        странице Интернет-магазина.
                        <br/>
                        5.2. Оплата производится в белорусских рублях в форме и способами, указанными на платежной
                        странице Интернет-магазина.
                        <br/>
                        5.3. Полная стоимость Заказа состоит из стоимости Товара и стоимости доставки, если Товар
                        приобретен с условием о его доставке за счет Покупателя.
                        <br/>
                        5.4. На сумму предварительной оплаты проценты за пользование коммерческим займом не начисляются.
                        <br/>
                        5.5. В случае неоплаты Заказа в установленный срок, Заказ аннулируется. Если предварительная
                        оплата произведена Покупателем после окончания установленного срока для оплаты, то данные
                        денежные средства либо возвращаются Покупателю как ошибочно перечисленные по его желанию,
                        выраженному в письменной форме, в том числе в электронном виде при помощи сети Интернет, либо
                        принимаются Продавцом в виде авансовых платежей (предоплаты) в счет будущих Заказов Покупателя,
                        оформленных в соответствии с условиями настоящего Договора.
                    </div>
                    <div className={'paragraphTitle'}>
                        6. Срок исполнения заказа. Доставка Товара.
                    </div>
                    <div className={'paragraph'}>
                        Срок исполнения Заказа и доставки Товара указывается в описании товара или на платежной странице
                        Интернет-магазина.
                        <br/>
                        7. Права и обязанности сторон.
                        <br/>
                        7.1. Продавец обязан:
                        <br/>
                        7.1.1. Передать указанный в Заказе Товар Покупателю в порядке и сроки, установленные настоящим
                        Договором.
                        <br/>
                        7.2. Покупатель обязан:
                        <br/>
                        7.2.1. До момента заключения Договора ознакомиться с содержанием и условиями Договора, ценами на
                        Товар, предлагаемыми Продавцом в Интернет-магазине.
                        <br/>
                        7.2.2. Во исполнение Продавцом своих обязательств перед Покупателем последний должен сообщить
                        все необходимые данные, однозначно идентифицирующие его как Покупателя, и достаточные для
                        доставки Покупателю оплаченного им Товара.
                        <br/>
                        7.2.3. Произвести оплату в порядке, сроки и размере, предусмотренном настоящим Договором.
                        <br/>
                        7.2.4. Своевременно принять товар.
                        <br/>
                        7.2.5. Покупатель самостоятельно проверяет соответствие доставленного Товара оформленному
                        Заказу.
                        <br/>
                        7.2.6. При приемке товара Покупатель обязан проверить качество Товара и количество вещей.
                    </div>
                    <div className={'paragraphTitle'}>
                        8. Гарантии качества товара. Порядок возврата.
                    </div>
                    <div className={'paragraph'}>
                        При приобретении Товара в Интернет-магазине за Покупателем сохраняются все права,
                        гарантированные Законом Республики Беларусь «О защите прав потребителей». Порядок возврата
                        Товара определен в разделе «Возврат» Интернет-магазина.
                    </div>
                    <div className={'paragraphTitle'}>
                        9. Отзыв оферты.
                    </div>
                    <div className={'paragraph'}>
                        9.1. Настоящая оферта не является безотзывной, Продавец имеет право отказать в размещении Заказа
                        лицам, выражающим несогласие с условиями настоящего Договора.
                        <br/>
                        9.2. Отзыв оферты (Договора) может быть осуществлён Продавцом в любое время, но это не является
                        основанием для отказа от обязательств Продавца по уже заключённым договорам. Продавец обязуется
                        разместить уведомление об отзыве оферты в Интернет-магазине с указанием точного времени отзыва
                        оферты не менее чем за 12 (двенадцать) часов до факта наступления события отзыва
                        (приостановки)действия Оферты.
                    </div>
                    <div className={'paragraphTitle'}>
                        10. Ответственность сторон.
                    </div>
                    <div className={'paragraph'}>
                        10.1. Каждая из сторон обязана исполнять свои обязательства надлежащим образом, оказывая
                        возможное содействие другой Стороне.
                        <br/>
                        10.2. Продавец не несет ответственности за убытки Покупателя, возникшие в результате допущенных
                        ошибок при заполнении формы Заказа или при регистрации; в результате неправомерных действий
                        третьих лиц, а также за ненадлежащее использование Покупателем Товаров, заказанных в
                        Интернет-магазине.
                        <br/>
                        10.3. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих
                        обязанностей в соответствии с действующим законодательством Республики Беларусь.
                        <br/>
                        10.4. Споры и разногласия, возникающие между Сторонами, разрешаются путём переговоров, в
                        соответствии с действующим законодательством Республики Беларусь. Стороны обязаны принять все
                        меры для разрешения разногласий в досудебном порядке. Покупатель до предъявления иска в суд
                        обязан при наличии у него претензии направить ее на юридический/почтовый адрес Продавца либо на
                        адрес электронной почты info@republic-club.by с обоснованием причин и подтверждающих документов.
                        Продавец обязан рассмотреть полученную претензию и направить Покупателю на его электронный или
                        фактический адрес (указанный при регистрации и оформлении Заказа) мотивированный ответ в течение
                        14 (четырнадцати) календарных дней с момента получения претензии.
                        <br/>
                        10.5. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств
                        по настоящему Договору, если такое неисполнение вызвано обстоятельствами непреодолимой силы
                        (форс- мажорные обстоятельства).
                    </div>
                    <div className={'paragraphTitle'}>
                        11. Прочие условия
                    </div>
                    <div className={'paragraph'}>
                        11.1. К отношениям между Продавцом и Покупателем применяются положения и требования
                        законодательства Республики Беларусь, в том числе:
                        <br/>
                        <span className={'listItem'}>
                            •Параграфа 2 главы 30 ГК РБ (Розничная купля-продажа).
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •Закона Республики Беларусь от 8 января 2014 г. N 128-З «О государственном регулировании
                        торговли и общественного питания в Республике Беларусь».
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •Закона Республики Беларусь от 9 января 2002 г. № 90-З «О защите прав потребителей».
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •Постановления Совета Министров Республики Беларусь 15 января 2009 г. № 31 «Об утверждении
                        Правил продажи товаров при осуществлении розничной торговли по образцам».
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •Постановление Совета Министров Республики Беларусь от 14 июня 2002 г. № 778 «О мерах по
                        реализации Закона Республики Беларусь «О защите прав потребителей» (Перечень непродовольственных
                        товаров надлежащего качества, не подлежащих обмену и возврату).
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •Указа Президента Республики Беларусь от 30.08.2011 № 389 “О едином расчетном и информационном
                        пространстве в Республике Беларусь».
                        </span>
                        <br/>
                        <span className={'listItem'}>
                        •Иных нормативных правовых актов Республики Беларусь, регулирующих вопросы розничной
                        купли-продажи.
                        </span>
                        <br/>
                        11.2. Стороны безоговорочно признают юридическую силу текстов документов, сообщений, полученных
                        по каналам связи (электронной почты и/или в глобальной компьютерной сети интернет, в том числе
                        на сайте www.republic-club.by) наравне с документами, исполненными в простой письменной форме на
                        бумажном носителе, за исключением случаев, когда оформление документов на бумажном носителе
                        является обязательным в силу требований настоящего Договора или законодательства Республики
                        Беларусь. Вся переписка, извещения и уведомления, полученные посредством электронного сообщения,
                        указанные в настоящем Договоре как реквизиты Сторон, считаются доставленными адресату в
                        надлежащей форме.
                        <br/>
                        11.3. Вся текстовая информация и графические изображения, размещенные на интернет-сайте
                        www.republic-club.by, являются собственностью Продавца.
                        <br/>
                        11.4. Продавец вправе направлять Покупателю сообщения рекламно-информационного характера. В
                        случае, если Покупатель не желает получать рассылки от Продавца, он должен известить об этом
                        Продавца через форму «Обратной связи» на сайте Интернет-магазина.
                        <br/>
                        11.5. Интернет- магазин www.republic-club.by внесен в Торговый реестр
                    </div>
                    <div className={'paragraphTitle'}>
                        12. Реквизиты продавца
                    </div>
                    <div className={'paragraph'}>
                        ИП Старцев Андрей Валентинович
                        <br/>
                        220140 г. Минск, ул. Притыцкого, дом 62
                        <br/>
                        УНП 190462465, р/с BY25MTBK30130001093300032970 в ЗАО «МТБанк»,
                        <br/>
                        БИК MTBKBY22
                        <br/>
                        220033, г. Минск, пр. Партизанский, д. 6А
                    </div>
                </div>
            </div>
    )
}
