import {instance} from '../instance'


export const cartAPI = {
    getCart(status: string, name: string='') {
        return instance.get(`cart?itemStatus=${status}`)
    },
    addItemToCart(itemPriceId: number, quantity: number = 1){
        return instance.post(`cart/choose/${itemPriceId}?quantity=${quantity}`)
    },
    updateCart(cartId: number, quantity: number){
        return instance.put(`cart?cartId=${cartId}&quantity=${quantity}`)
    },
    deleteItemFromCart(cartId: number) {
        return instance.delete(`cart?idCart=${cartId}`)
    }
}
