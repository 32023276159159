import {categoriesAPI} from "../../api/shop/categoryAPI";
import { ICategory } from "../../types/category";
import {BaseThunkType, InferActionsType} from "../store";
import {commonThunkCreator} from "./commonReducer";

type ThunkType = BaseThunkType<IActions>;
export type IActions = InferActionsType<typeof categoryActions>;
export type ICategoriesReducer = typeof initialState;

const initialState = {
    categories: [] as ICategory[],
    category: {} as ICategory | undefined
}

export const categoriesReducer = (state = initialState, action: IActions): ICategoriesReducer => {
    switch (action.type) {
        case "SET_CATEGORIES":
            return {
                ...state,
                categories: action.payload.categories
            }
        case "SET_CATEGORY":
            return {
                ...state,
                category: state.categories.find(i => i.id === action.payload.id)
            }
        default:
            return state
    }
}

export const categoryActions = {
    setCategoriesAC: (categories: ICategory[]) =>
        ({
            type: "SET_CATEGORIES",
            payload: {categories},
        } as const),
    setCategoryAC: (id: number) =>
        ({
            type: "SET_CATEGORY",
            payload: {id},
        } as const),
};

export const getCategoriesTC = (id?: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const categories = await categoriesAPI.getCategories();
        dispatch(categoryActions.setCategoriesAC(categories.data));
        if (id) {
            dispatch(categoryActions.setCategoryAC(id));
        }
    }, dispatch);
};


