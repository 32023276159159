import { IProfile } from '../../types/profile'
import {instanceEmptyPath} from '../instance'

export const profileAPI = {
    getProfile() {
        return instanceEmptyPath.get(`api/profile`)
    },
    updateProfile(newProfile: IProfile) {
        return instanceEmptyPath.post(`api/profile`, newProfile)
    },
}
