import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {useParams} from "react-router-dom";
import {getItemsTC} from "../../redux/reducers/items-reducer";
import "./Category.scss";
import {getCategoriesTC} from "../../redux/reducers/categoryReducer";
import {ICategory} from "../../types/category";
import {IItems} from "../../types/items";
import {Layout} from "./Layout";

export const Category = () => {
    const dispatch = useDispatch()
    const items = useSelector<RootStateType, { [key: string]: IItems[] }>(state => state.items.items)
    const category = useSelector<RootStateType, ICategory | undefined>(state => state.categories.category)
    const {categoryUrlId}: any = useParams()
    const categoryId = Number(categoryUrlId)

    useEffect(() => {
        dispatch(getCategoriesTC(categoryId))
        dispatch(getItemsTC(categoryId))
    }, [dispatch, categoryId])

    const itemsForCategory = items[categoryId]

    return <Layout itemsForCategory={itemsForCategory}
                   category={category}
        />

}
