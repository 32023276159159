import {priceAPI} from "../../api/shop/priceAPI";
import {itemsActions} from "./items-reducer";
import {commonThunkCreator} from "./commonReducer";
import {BaseThunkType, InferActionsType} from "../store";
import {IItemPrice} from "../../types/price";

type ThunkType = BaseThunkType<IActions | any>;
export type IActions = InferActionsType<typeof priceActions>;
export type IPriceReducer = typeof initialState;

const initialState = {
    prices: [] as IItemPrice[],
}

export const priceReducer = (state = initialState, action: IActions): IPriceReducer => {
    switch (action.type) {
        case "SET-ITEMS-PRICES":
            return {
                ...state,
                prices: action.payload.prices
            }
        default:
            return state
    }
}

export const priceActions = {
    setPricesItemsAC: (prices: IItemPrice[]) =>
        ({type: 'SET-ITEMS-PRICES', payload: {prices}} as const),
};

export const getItemPriceTC = (itemId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const prices = await priceAPI.getPrice(itemId)
        dispatch(priceActions.setPricesItemsAC(prices.data.itemPrice))
        dispatch(itemsActions.setItemAC(prices.data.item))
    }, dispatch);
};
