import React from 'react'
import './Title.scss'

interface IProps {
    title: string
}

export const Title = ({title}: IProps) => {
    return (
            <div className={'title-component'}>
                {title}
            </div>
    )
}
