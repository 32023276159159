import {orderAPI} from "../../api/shop/orderAPI";
import {cartActions} from "./cartReducer";
import {commonActions, commonThunkCreator} from "./commonReducer";
import {BaseThunkType, InferActionsType} from "../store";
import {IOrder, IOrderDescription} from "../../types/order";

type ThunkType = BaseThunkType<IActions | any>;
export type IActions = InferActionsType<typeof orderActions>;
export type IOrderReducer = typeof initialState;

const initialState = {
    order: {} as IOrderDescription,
    orders: [] as IOrder[],
    totalElements: 0 as number
}

export const orderReducer = (state = initialState, action: IActions): IOrderReducer => {
    switch (action.type) {
        case "SET_ORDER":
            return {
                ...state,
                order: action.payload.order
            }
        case "SET_ORDERS":
            return {
                ...state,
                orders: action.payload.orders,
                totalElements: action.payload.totalElements
            }
        default:
            return state
    }
}

export const orderActions = {
    setOrderAC: (order: IOrderDescription) =>
        ({type: "SET_ORDER", payload: {order}} as const),
    setOrdersAC: (orders: IOrder[], totalElements: number) =>
        ({type: "SET_ORDERS", payload: {orders, totalElements}} as const)
};

export const createOrderTC = (addressId: number, history: any): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const order = await orderAPI.createOrder(addressId)
        dispatch(orderActions.setOrderAC(order.data))
        history.push(`/order/${order.data.id}`)
        dispatch(commonActions.setSuccessToastAC("Заказ успешно создан"))
        dispatch(cartActions.setCartsAC([], 0, 0))
    }, dispatch);
};
export const getOrdersTC = (): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const orders = await orderAPI.getOrders()
        dispatch(orderActions.setOrdersAC(orders.data.content, orders.data.totalElements))
    }, dispatch);
};

export const getOrderTC = (orderId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const order = await orderAPI.getOrder(orderId)
        dispatch(orderActions.setOrderAC(order.data))
    }, dispatch);
};
