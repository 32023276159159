import React from 'react';
import './LoggedInLinks.scss'
import {NavLink} from "react-router-dom";
import cart from "../../../assest/icons/cart.png";
import profile from "../../../assest/icons/user.png";

interface IProps {
    isAuth: boolean
    cartCount: number
}

export const LoggedInLinks = ({isAuth,cartCount}: IProps) => {

    if (!isAuth) {
        return null
    }

    return (
            <>
                <div className={"cart-icon-container"}>
                    <NavLink to='/basket'>
                        <img className={'logged-in-header-icon '} alt={'cart'} src={cart}/>
                    </NavLink>
                    {!!cartCount && <span className={'basket-count-items'}>{cartCount}</span>}
                </div>
                <NavLink to='/profile/info'>
                    <img className={'logged-in-header-icon'} alt={'profile'} src={profile}/>
                </NavLink>
            </>
    );
};
