import React from 'react'
import './MainImage.scss'
import {CarouselProvider, Slide, Slider} from "pure-react-carousel"
import {config} from "../../../../config/config"
import noPhoto from "../../../../assest/images/no-photo.png"

interface IProps {
    currentImage: string | null
}

export const MainImage = ({currentImage}:IProps) => {
    return (
            <div className={'main-image-container'}>
                <CarouselProvider
                        dragEnabled={false}
                        touchEnabled={false}
                        naturalSlideWidth={100}
                        naturalSlideHeight={100}
                        totalSlides={1}
                >
                    <Slider className={'main-image'}>
                        <Slide index={1}>
                            <img alt={''} className={'main-image'} src={currentImage ? config.baseImgPathUrl + currentImage : noPhoto}/>
                        </Slide>
                    </Slider>
                </CarouselProvider>
            </div>
    );
};
