import React, {ChangeEvent} from 'react'
import './RadioColors.scss'
import {IColour} from "../../types/colour";

interface IProps {
    item: IColour
    onChange: (id: number) => void
    filter: number | null
}

export const RadioColors = (props: IProps) => {
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange(Number(e.currentTarget.value))
    }
    return (
            <div className={'radio-colors-container'}>
                <input type='radio'
                       className={'radio-color'}
                       value={props.item.id}
                       id={`${props.item.id}`}
                       checked={props.item.id === props.filter}
                       onChange={onChangeHandler}/>
                <label style={{backgroundColor: props.item.code}} className={'radio-label-color'} htmlFor={`${props.item.id}`}/>
            </div>
    )
}
