import React from 'react'
import "./BasketItems.scss";
import {BasketItem} from "./basketItem/BasketItem";
import { ICart } from '../../../../types/cart';

interface IProps {
    carts: ICart[]
}

export const BasketItems = ({carts}: IProps) => {
    return (
            <div className={'basketItems'}>
                <div className={'list'}>
                    {carts.map(c => <BasketItem key={c.cartId} cart={c}/>)}
                </div>
            </div>
    )
}
