export enum TEXT {
    TITLE = 'МОЯ КОРЗИНА',
    ADDRESS_TITLE = 'АДРЕС ДОСТАВКИ',
    CHANGE_ADDRESS_BUTTON = 'Изменить адрес',
    ADD_ADDRESS_BUTTON = '+Добавить адрес',
    EMPTY_CART = 'ВАША КОРЗИНА ПУСТА',
    CREATE_ORDER_BUTTON = 'ОФОРМИТЬ ЗАКАЗ И ОПЛАТИТЬ КАРТОЙ ОНЛАЙН',
    DELETE_ITEM_BUTTON = 'Удалить товар',
    COLOR = 'Цвет:',
    SIZE = 'Размер:',
    INCREASE = '+',
    DECREASE = '-',
}

export enum COLUMNS {
    PRICE = 'ЦЕНА',
    COUNT = 'КОЛИЧЕСТВО',
    AMOUNT = 'ЦЕНА',
}
