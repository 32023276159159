import React from 'react'
import './BackComponent.scss'
import {useHistory} from "react-router-dom";
import backArrow from '../../assest/images/back-arrow.png'

export const BackComponent = () => {
    const history = useHistory()
    return (
            <div className={'back-arrow-link-container'} onClick={() => history.goBack()}>
                <img src={backArrow} alt={''}/>
                <span className={'back-link-text'}>Назад</span>
            </div>
    )
}
