import React from 'react'
import {Title} from '../../../../components/title/Title'
import './Address.scss'
import {CurrentAddress} from "../../../../components/currentAddress/CurrentAddress";
import {ButtonString} from '../../../../components/buttons/buttonString/ButtonString';
import {TEXT} from "../../index";
import {IAddress} from "../../../../types/address";

interface IProps {
    currentAddress: IAddress | null
    defaultAddress: null | IAddress
    showChangeAddressHandler: () => void
    showAddAddressHandler: () => void
}

export const Address = (props: IProps) => {
    return (
            <div className={'basket-order-address-container'}>
                <Title title={TEXT.ADDRESS_TITLE}/>
                <div className={'basket-address-address-settings'}>
                    <CurrentAddress currentAddress={props.currentAddress} defaultAddress={props.defaultAddress}/>
                    <div className={'basket-address-buttons'}>
                        <ButtonString onClick={props.showChangeAddressHandler} title={TEXT.CHANGE_ADDRESS_BUTTON}/>
                        <ButtonString onClick={props.showAddAddressHandler} title={TEXT.ADD_ADDRESS_BUTTON}/>
                    </div>
                </div>
            </div>
    )
}
