import React from 'react'
import './TextInput.scss'
import {UseWindowDimensions} from "../../hooks/useWindowDimensions";

export const TextInput = ({
                              field: {name, value, onChange},
                              id,
                              label,
                              className,
                              width,
                              isBorder = false,
                              ...props
                          }: any) => {

    const windowDimensions = UseWindowDimensions()

    return (
            <input
                    name={name}
                    value={value}
                    onChange={onChange}
                    style={{width: width && windowDimensions.width > 770 ? width : "100%"}}
                    className={`${className} ${isBorder
                            ? 'text-input-component-with-border'
                            : 'text-input-component'}`}
                    {...props}
            />
    )
}
