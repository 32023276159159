import { Action, applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleWare, { ThunkAction } from "redux-thunk";
import {categoriesReducer} from "./reducers/categoryReducer";
import {itemsReducer} from "./reducers/items-reducer";
import {priceReducer} from "./reducers/priceReducer";
import {addressReducer} from "./reducers/addressReducer";
import {cartReducer} from "./reducers/cartReducer";
import {orderReducer} from "./reducers/orderReducer";
import {colourReducer} from "./reducers/colourReducer";
import {sizeReducer} from "./reducers/sizeReducer";
import {authReducer} from "./reducers/authReducer";
import {commonReducer} from "./reducers/commonReducer";
import {profileReducer} from "./reducers/profileReducer";


let reducers = combineReducers({
    categories: categoriesReducer,
    items: itemsReducer,
    prices: priceReducer,
    address: addressReducer,
    carts: cartReducer,
    order: orderReducer,
    colours: colourReducer,
    sizes: sizeReducer,
    auth: authReducer,
    common: commonReducer,
    profile: profileReducer
})

export const store = createStore(reducers, applyMiddleware(thunkMiddleWare))

type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
export type InferActionsType<
    T extends { [key: string]: (...args: any[]) => any }
    > = ReturnType<PropertiesType<T>>;

export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<
    R,
    RootStateType,
    unknown,
    A
    >;

export type RootStateType = ReturnType<typeof reducers>

// @ts-ignore
window.store = store
