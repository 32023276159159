export const routes = {
    auth: [
        {id: 'signin', path: '/login', title: 'ВОЙТИ'},
        {id: 'register', path: '/register', title: 'РЕГИСТРАЦИЯ'},
    ],
    header: [
        {id: 'main', path: '/', title: 'ГЛАВНАЯ'},
        {id: 'about', path: '/about', title: 'О НАС'},
        {id: 'rules-pay', path: '/rules-pay', title: 'ПРАВИЛА ОПЛАТЫ'},
        {id: 'public-offer', path: '/public-offer', title: 'ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ'},
    ],
    catalog: {id: 'catalog', path: '/category', title: 'КАТАЛОГ'},
}
