import React, {ChangeEvent} from 'react'
import './RadioAddreses.scss'
import {IAddress} from "../../types/address";

interface IProps {
    item: IAddress
    onChange: (id: number) => void
    filter: number | undefined
}

export const RadioAddresses = ({item, onChange, filter}: IProps) => {

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(Number(e.currentTarget.value))
    }

    return (
            <div className={'radio-addresses-container'}>
                <input type='radio'
                       style={{background: '#dfffff'}}
                       className={'radio-addresses'}
                       checked={item.id === filter}
                       id={item.id.toString()}
                       value={item.id}
                       onChange={onChangeHandler}/>
                <label className={'radio-label-addresses'} htmlFor={item.id.toString()}/>
                <div className={'radio-addresses-description'}>
                    {item.country && `${item.country}, `}
                    {item.state && `${item.state}, `}
                    {item.city && `${item.city}, `}
                    {item.description && `${item.description} `}
                </div>
            </div>
    )
}
