import React, {useEffect} from 'react'
import {commonActions} from "../../../redux/reducers/commonReducer";
import {useDispatch} from "react-redux";

export const ErrorFallBackPage = ({error, resetErrorBoundary}: any) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(commonActions.setErrorToastAC(`Что то пошло не так: ${error.message}`))
    },[dispatch,error.message])

    return (
            <div role="alert">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <button onClick={resetErrorBoundary}>Try again</button>
            </div>
    )
}
