import React, {useEffect, useState} from 'react'
import './ChangeAddress.scss'
import {BlackButton} from "../../buttons/blackButton/BlackButton";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store";
import {addressActions, getAddressesTC, IAddressReducer} from "../../../redux/reducers/addressReducer";
import {RadioAddresses} from "../../radioAddresses/RadioAddreses";
import {commonActions} from '../../../redux/reducers/commonReducer';

export const ChangeAddress = () => {
    const dispatch = useDispatch()
    const {
        addresses,
        defaultAddress,
        currentAddress
    } = useSelector<RootStateType, IAddressReducer>(state => state.address)
    const [variantAddress, setVariantAddress] = useState<number | undefined>(defaultAddress?.id)

    useEffect(() => {
        dispatch(getAddressesTC())
    }, [dispatch])
    useEffect(() => {
        currentAddress?.id
                ? setVariantAddress(currentAddress.id)
                : setVariantAddress(defaultAddress?.id)
    }, [currentAddress, defaultAddress])

    const onChangeAddress = (addressId: number) => {
        setVariantAddress(addressId)
    }
    const changeCurrentAddressId = () => {
        variantAddress && dispatch(addressActions.setCurrentAddressAC(variantAddress))
        dispatch(commonActions.setIsShowModalAC(false))
    }

    return (
            <div className={'change-address-body'}>
                <div className={'change-address-list'}>
                    {addresses.map(i => <RadioAddresses key={i.id}
                                                        filter={variantAddress}
                                                        item={i}
                                                        onChange={onChangeAddress}/>
                    )}
                </div>
                <BlackButton title={'Сохранить'} type={'button'} onClick={changeCurrentAddressId}/>
            </div>
    )
}
