import React from 'react'
import './StaticsPage.scss'
import { ReactComponent as  NotFoundIcon } from '../../assest/images/notFound.svg'

export const NotFound = () => {
    return (
            <div className={'page'}>
                <div className={'not-found-container'}>
                    <div className={'not-found-text-large'}>
                        Whoops!
                    </div>
                    <div>
                        <NotFoundIcon className={'not-found-icon'} />
                    </div>
                    <div className={'not-found-text-large'}>
                        Error 404
                    </div>
                    <div className={'not-found-text-small'}>
                        Page not found
                    </div>
                </div>
            </div>
    );
};
