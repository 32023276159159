import React from 'react'
import './Preloader.scss'
import {useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";

export const Preloader = () => {
    const loader = useSelector<RootStateType, boolean>(state => state.common.isLoading)
    if(!loader){
        return null
    }
    return (
            <div className="preloader-container">
                <div className="preloader-zenith"/>
            </div>
    )
}
