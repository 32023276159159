import React, {useState} from 'react';
import './ChangeUserInfo.scss'
import {BlackButton} from "../../buttons/blackButton/BlackButton";
import {useDispatch, useSelector} from "react-redux";
import {commonActions} from "../../../redux/reducers/commonReducer";
import {Field, Form, Formik} from "formik";
import {TextInput} from "../../textInput/TextInput";
import {RootStateType} from "../../../redux/store";
import {updateProfileInfoTC} from "../../../redux/reducers/profileReducer";
import {InputWithPhoneMask} from "../../textInput/inputWithPhoneMask/InputWithPhoneMask";
import {onlyStringValidate} from "../../../utils/validate";
import {CHANGE_USER_INFO_FIELDS, MODAL_ERRORS} from '../constants';
import {IChangeUserInfoForm, IProfile} from '../../../types/profile';

export const ChangeUserInfo = () => {
    const dispatch = useDispatch()
    const [errorFields, setErrorFields] = useState<CHANGE_USER_INFO_FIELDS[]>([])

    const closeModal = () => {
        dispatch(commonActions.setIsShowModalAC(false))
    }
    const profile = useSelector<RootStateType, IProfile>(state => state.profile.profile)

    const onSubmit = (values: IChangeUserInfoForm) => {
        if (errorFields.length === 0) {
            dispatch(updateProfileInfoTC(values))
        }
    }
    const validate = (values: IChangeUserInfoForm) => {
        const errors = []
        if (!values.phone && !values.lastname && !values.firstname && !values.birthDate) {
            dispatch(commonActions.setErrorToastAC(MODAL_ERRORS.INCORRECT_ALL_FIELDS))
            return setErrorFields([CHANGE_USER_INFO_FIELDS.ALL])
        }
        console.log(values.phone)
        if (!values.phone || values.phone.length < 14) {
            errors.push(CHANGE_USER_INFO_FIELDS.PHONE)
        }
        if (onlyStringValidate(values.lastname)) {
            errors.push(CHANGE_USER_INFO_FIELDS.LASTNAME)
        }
        if (onlyStringValidate(values.firstname)) {
            errors.push(CHANGE_USER_INFO_FIELDS.FIRSTNAME)
        }
        if (!values.birthDate) {
            errors.push(CHANGE_USER_INFO_FIELDS.BIRTH_DAY)
        }
        if (errors.length > 0) {
            dispatch(commonActions.setErrorToastAC(MODAL_ERRORS.INCORRECT_FORMAT))
        }
        setErrorFields(errors)
    }

    return (
            <div className={'change-user-info-body'}>
                <div className={'change-user-info-list'}>
                    <Formik initialValues={{
                        lastname: profile.lastname || '',
                        firstname: profile.firstname || '',
                        phone: profile.phone || '',
                        birthDate: profile.birthDate || '',
                    }}
                            validateOnChange={false}
                            onSubmit={(values) => onSubmit(values)}
                            validate={validate}
                    >
                        <Form className={'change-user-info-form'}>
                            <div className={'change-user-info-personal-data'}>
                                <div className={'change-user-info-personal-data-row'}>
                                    <div className={'change-user-info-personal-data-row-name'}>{`Фамилия`}</div>
                                    <Field component={TextInput}
                                           placeholder={`Фамилия`}
                                           name={CHANGE_USER_INFO_FIELDS.LASTNAME}
                                           className={(errorFields.includes(CHANGE_USER_INFO_FIELDS.LASTNAME) || errorFields.includes(CHANGE_USER_INFO_FIELDS.ALL)) && `error-input`}
                                    />
                                </div>
                                <div className={'change-user-info-personal-data-row'}>
                                    <div className={'change-user-info-personal-data-row-name'}>{`Имя`}</div>
                                    <Field component={TextInput}
                                           placeholder={'Имя'}
                                           name={CHANGE_USER_INFO_FIELDS.FIRSTNAME}
                                           className={(errorFields.includes(CHANGE_USER_INFO_FIELDS.FIRSTNAME) || errorFields.includes(CHANGE_USER_INFO_FIELDS.ALL)) && `error-input`}
                                    />
                                </div>
                                <div className={'change-user-info-personal-data-row'}>
                                    <div className={'change-user-info-personal-data-row-name'}>{`Телефон`}</div>
                                    <Field component={InputWithPhoneMask}
                                           placeholder={'Телефон'}
                                           name={CHANGE_USER_INFO_FIELDS.PHONE}
                                           className={(errorFields.includes(CHANGE_USER_INFO_FIELDS.PHONE) || errorFields.includes(CHANGE_USER_INFO_FIELDS.ALL)) && `error-input`}
                                    />
                                </div>
                                <div className={'change-user-info-personal-data-row'}>
                                    <div className={'change-user-info-personal-data-row-name'}>{`Дата рождения`}</div>
                                    <Field component={TextInput}
                                           type={"date"}
                                           placeholder={'Дата рождения'}
                                           name={CHANGE_USER_INFO_FIELDS.BIRTH_DAY}
                                           className={(errorFields.includes(CHANGE_USER_INFO_FIELDS.BIRTH_DAY) || errorFields.includes(CHANGE_USER_INFO_FIELDS.ALL)) && `error-input`}
                                    />
                                </div>
                            </div>
                            <div className={'change-user-info-button-container'}>
                                <BlackButton title={'Сохранить'} type={'submit'}/>
                                <BlackButton title={'Отмена'} type={'button'} onClick={closeModal}/>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
    );
};
