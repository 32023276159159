import React from 'react';
import {BackComponent, Title} from "../../components";
import {NavLink} from "react-router-dom";
import {config} from "../../config/config";
import noPhoto from "../../assest/images/no-photo.png";
import {IItems} from "../../types/items";
import {ICategory} from "../../types/category";

interface IProps {
    itemsForCategory: IItems[]
    category?: ICategory
}

export const Layout = ({itemsForCategory, category}: IProps) => {
    return (
            <section className={'category'}>
                <div className={'category-container'}>
                    <BackComponent/>
                    <Title title={`КАТЕГОРИЯ: ${category?.name?.toUpperCase()}`}/>

                    <div className={'category-item-container'}>
                        {itemsForCategory && itemsForCategory.map(i => {
                            return (
                                    <NavLink to={`/item/${i.itemId}`} key={i.itemId} className={'category-item'}>
                                        <img className={'category-item-img'} alt={'category-item'}
                                             src={config.baseImgPathUrl + (i.webPath ? i.webPath : noPhoto)}/>
                                        <div className={'category-item-title'}>
                                            {i.name}
                                        </div>
                                        <div className={'category-item-price'}>
                                            {`от ${i.minPrice} BYN до ${i.maxPrice} BYN`}
                                        </div>
                                        <div key={i.itemId} className={'category-item-link-button'}>
                                            Купить
                                        </div>
                                    </NavLink>
                            )
                        })}
                    </div>
                </div>


            </section>
    );
};
