import React, {useEffect, useState} from 'react'
import "./BasketItem.scss";
import {NavLink} from "react-router-dom";
import {changeCartItemCountTC, deleteItemFromCartTC} from "../../../../../redux/reducers/cartReducer";
import {useDispatch} from "react-redux";
import {config} from '../../../../../config/config';
import {ButtonString} from "../../../../../components/buttons/buttonString/ButtonString";
import {ChangeItemsCount} from "./changeItemCount/ChangeItemsCount";
import {TEXT} from "../../../index";
import {ICart} from "../../../../../types/cart";

interface IProps {
    cart: ICart
}

export const BasketItem = ({cart}: IProps) => {
    const dispatch = useDispatch()

    const [itemCount, setItemCount] = useState(Number(cart.quantity))
    const deleteItemFromCart = (cartId: number) => {
        dispatch(deleteItemFromCartTC(cartId))
    }

    useEffect(() => {
        setItemCount(Number(cart.quantity))
    },[cart])
    console.log(itemCount)
    const decreaseHandler = () => {
        dispatch(changeCartItemCountTC(cart.cartId, itemCount - 1))
    }
    const increaseHandler = () => {
        dispatch(changeCartItemCountTC(cart.cartId, itemCount + 1))
    }
    const onChangeItemCount = (count: number) => {
        setItemCount(count)
    }
    const onBlurHandler = (count: number) => {
        if (!count || count < 1) {
            setItemCount(Number(cart.quantity))
        } else {
            dispatch(changeCartItemCountTC(cart.cartId, itemCount))
        }
    }

    return (
            <div key={cart.cartId} className={'basket-item'}>
                <div className={'basket-item-description-container'}>
                    <img className={'basket-item-img'}
                         src={config.baseImgPathUrl + cart.photoWebPath}
                         alt={'basket img'}/>
                    <div className={'basket-item-description'}>
                        <NavLink to={'/item/' + cart.itemId}
                                 className={'basket-item-description-link'}
                        >
                            {cart.itemName}
                        </NavLink>
                        <div className={'basket-item-info-container'}>
                            <div className={'basket-item-info-text'}>
                                {`${TEXT.COLOR} ${cart.colourName}`}
                            </div>
                            <div className={'basket-item-info-text'}>
                                {`${TEXT.SIZE} ${cart.sizeName}`}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'basket-item-actions-container'}>
                    <div className={'basket-item-actions'}>
                        <div className={'basket-item-actions-text'}>
                            {`${cart.amount} BYN`}
                        </div>
                        <ChangeItemsCount
                                itemCount={itemCount}
                                decreaseHandler={decreaseHandler}
                                onChangeItemCount={onChangeItemCount}
                                onBlurHandler={onBlurHandler}
                                increaseHandler={increaseHandler}
                        />
                        <div className={'basket-item-actions-text'}>
                            {`${cart.amount * itemCount ? cart.amount * itemCount : 0} BYN`}
                        </div>
                    </div>
                    <ButtonString onClick={() => deleteItemFromCart(cart.cartId)}
                                  title={TEXT.DELETE_ITEM_BUTTON}
                    />
                </div>
            </div>
    )
}
