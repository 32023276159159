import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {RootStateType} from "../../redux/store";
import {BURGER_MENU_STATUS} from "./components/constants";
import {BurgerIcon} from "./components/burgerIcon/BurgerIcon";
import {BurgerItems} from "./components/burgerItems/BurgerItems";
import {ICategory} from "../../types/category";

export const Burger = () => {
    const location = useLocation();

    const [burgerStatus, setBurgerStatus] = useState<BURGER_MENU_STATUS>(BURGER_MENU_STATUS.CLOSE);
    const [showSubItems, setIsShowSubItems] = useState(false)

    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)
    const categories = useSelector<RootStateType, ICategory[]>(state => state.categories.categories)

    useEffect(() => {
        setBurgerStatus(BURGER_MENU_STATUS.CLOSE)
        setIsShowSubItems(false)
    }, [location])

    const showSubItemsHandler = () => {
        setIsShowSubItems(!showSubItems)
    }

    const changeBurgerStatus = () => {
        BURGER_MENU_STATUS.OPEN === burgerStatus
                ? setBurgerStatus(BURGER_MENU_STATUS.CLOSE)
                : setBurgerStatus(BURGER_MENU_STATUS.OPEN)
    }

    return (
            <>
                <BurgerIcon changeBurgerStatus={changeBurgerStatus}
                            burgerStatus={burgerStatus}
                />
                <BurgerItems isAuth={isAuth}
                             showSubItemsHandler={showSubItemsHandler}
                             changeBurgerStatus={changeBurgerStatus}
                             showSubItems={showSubItems}
                             burgerStatus={burgerStatus}
                             categories={categories}
                />
            </>

    );
};
