import React, {useEffect} from 'react'
import {Route, Switch, useLocation} from "react-router-dom";
import {Main} from "../pages/main/Main";
import {Item} from "../pages/item/Item";
import {Profile} from "../pages/profile/Profile";
import {PrivateRoute} from "../utils/PrivateRoute";
import {authMeTC} from "../redux/reducers/authReducer";
import {getCartsTC} from "../redux/reducers/cartReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../redux/store";
import {Basket, Category, Register, Login, ConfirmEmail, AboutClub, PublicOffer, RulesPay, NotFound} from '../pages';
import {Order} from "../pages/orders/order/Order";
import {commonActions} from "../redux/reducers/commonReducer";
import {MODAL_WINDOWS} from "../components/modalWindow/ModalWindow";
import {ScrollTop} from "../utils/ScrollTop";

export const Routes = () => {
    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)
    const dispatch = useDispatch();
    const location = useLocation<any>();
    const background = location.state && location.state.background
    useEffect(() => {
        !isAuth && dispatch(authMeTC())
        isAuth && dispatch(getCartsTC("CHOOSE"))
    }, [dispatch, isAuth])

    useEffect(() => {
        if (location.pathname === '/login') {
            dispatch(commonActions.setIsShowModalAC(true, MODAL_WINDOWS.LOGIN))
        } else if (location.pathname === '/register') {
            dispatch(commonActions.setIsShowModalAC(true, MODAL_WINDOWS.REGISTER))
        }
    }, [dispatch, location.pathname])

    return (
            <div>
                <ScrollTop/>
                <Switch location={background || location}>
                    <Route path='/login' render={() => <Main/>}/>
                    <Route path='/register' render={() => <Main/>}/>
                    <Route path='/confirmation-of-registration' render={() => <ConfirmEmail/>}/>
                    <Route path='/category/:categoryUrlId' render={() => <Category/>}/>
                    <Route exact path='/' render={() => <Main/>}/>
                    <Route path='/logout' render={() => <Main/>}/>
                    <Route path={'/item/:itemUrlId'} render={() => <Item/>}/>
                    <Route path='/rules-pay' render={() => <RulesPay/>}/>
                    <Route path='/about' render={() => <AboutClub/>}/>
                    <Route path='/public-offer' render={() => <PublicOffer/>}/>
                    <Route path='/confirm-email/:token' render={() => <ConfirmEmail/>}/>

                    <PrivateRoute path='/basket' component={Basket}/>
                    <PrivateRoute path='/order/:orderUrlId' component={Order}/>
                    <PrivateRoute path='/profile' component={Profile}/>
                    <Route render={() => <NotFound/>}/>
                </Switch>
                {background && <Route path='/login' render={() => <Login/>}/>}
                {background && <Route path='/register' render={() => <Register/>}/>}
            </div>
    )
}
