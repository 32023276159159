import {commonActions, commonThunkCreator} from "./commonReducer";
import {BaseThunkType, InferActionsType} from "../store";
import {profileAPI} from "../../api/shop/profileAPI";
import {IProfile} from "../../types/profile";

type ThunkType = BaseThunkType<IActions | any>;
export type IActions = InferActionsType<typeof profileActions>;
export type IPriceReducer = typeof initialState;

const initialState = {
    profile: {} as IProfile,
    isConfirm: false
}

export const profileReducer = (state = initialState, action: IActions): IPriceReducer => {
    switch (action.type) {
        case "SET_PROFILE_INFO":
            return {
                ...state,
                profile: action.payload.profile
            }
        default:
            return state
    }
}

export const profileActions = {
    setProfileInfoAC: (profile: IProfile) =>
        ({type: 'SET_PROFILE_INFO', payload: {profile}} as const),
    setIsConfirmEmailAC: (isConfirm: boolean) =>
        ({type: 'SET_IS_CONFIRM_EMAIL', payload: {isConfirm}} as const),
};

export const getProfileInfoTC = (): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const profile = await profileAPI.getProfile()
        dispatch(profileActions.setProfileInfoAC(profile.data))
    }, dispatch);
};
export const updateProfileInfoTC = (newProfile: IProfile): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await profileAPI.updateProfile(newProfile)
        await dispatch(getProfileInfoTC())
        dispatch(commonActions.setIsShowModalAC(false))
    }, dispatch);
};
