import React from 'react'
import './SmallImage.scss'
import {config} from "../../../../../config/config"
import {Slide} from "pure-react-carousel"
import {IPhotos} from "../../../../../types/items";

interface IProps {
    item: IPhotos
    changeCurrentImageHandler:(values: string) => void
    index: number
}

export const SmallImage = ({item, changeCurrentImageHandler,index}: IProps) => {
    const changeCurrentImage = (path: string) => {
        changeCurrentImageHandler(path)
    }
    return (
            <Slide onClick={() => changeCurrentImage(item.path)}
                   index={index}>
                <img alt={''}
                     className={'small-image'}
                     src={config.baseImgPathUrl + item.path}/>
            </Slide>
    );
};
