import React, {useEffect, useState} from 'react'
import './Addresses.scss'
import {Title} from "../../../components/title/Title";
import {RadioAddresses} from "../../../components/radioAddresses/RadioAddreses";
import {BlackButton} from "../../../components/buttons/blackButton/BlackButton";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store";
import {getAddressesTC, IAddressReducer, setDefaultAddressTC} from "../../../redux/reducers/addressReducer";
import {commonActions} from "../../../redux/reducers/commonReducer";
import { MODAL_WINDOWS } from '../../../components/modalWindow/ModalWindow';

export const Addresses = () => {
    const dispatch = useDispatch()
    const {addresses, defaultAddress} = useSelector<RootStateType, IAddressReducer>(state => state.address)
    const [variantAddress, setVariantAddress] = useState<number | undefined>(defaultAddress?.id)
    useEffect(() => {
        dispatch(getAddressesTC())
    }, [dispatch])
    useEffect(() => {
       setVariantAddress(defaultAddress?.id)
    },[defaultAddress ])

    const onChangeAddress = (addressId: number) => {
        setVariantAddress(addressId)
    }
    const showAddAddressModal = () => {
        dispatch(commonActions.setIsShowModalAC(true, MODAL_WINDOWS.ADD_ADDRESS))
    }
    const saveCurrentAddress = () => {
        variantAddress && dispatch(setDefaultAddressTC(variantAddress))
    }

    return (
            <div className={'profile-addresses'}>
                <Title title={'Выбрать адрес доставки:'}/>
                <div className={'profile-addresses-list'}>
                    {
                        addresses.map(i => <RadioAddresses key={i.id} filter={variantAddress} item={i}
                                                           onChange={onChangeAddress}/>)
                    }
                </div>
                <div className={'profile-addresses-buttons'}>
                    <BlackButton title={'Сохранить'} type={'button'} onClick={saveCurrentAddress}/>
                    <BlackButton title={'Добаить адрес'} type={'button'} onClick={showAddAddressModal}/>
                </div>
            </div>
    )
}
