import React, {useEffect} from 'react'
import './OrderList.scss'
import {useDispatch, useSelector} from "react-redux";
import {getOrdersTC, IOrderReducer} from "../../../redux/reducers/orderReducer";
import {RootStateType} from "../../../redux/store";
import { OrderRow } from './orderRow/OrderRow';

export const OrderList = () => {

    const dispatch = useDispatch()
    const {orders} = useSelector<RootStateType, IOrderReducer>(state => state.order)

    useEffect(() => {
        dispatch(getOrdersTC())
    }, [dispatch])

    return (
            <div className={'order-list'}>
                <div className={'order-list-columns'}>
                    <div className={'order-list-column-title'}>
                        Номер заказа
                    </div>
                    <div className={'order-list-column-title'}>
                        Дата
                    </div>
                    <div className={'order-list-column-title'}>
                        Сумма
                    </div>
                    <div className={'order-list-column-title'}>
                        Адрес
                    </div>
                </div>
                {orders.map(i => <OrderRow item={i} key={i.id} />)}
            </div>
    )
}
