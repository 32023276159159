import React, {ChangeEvent} from 'react'
import './RadioSizes.scss'
import {ISizes} from "../../types/size";

interface IProps {
    item: ISizes
    onChange: (id: number) => void
    filter: number | null
    currentColor: number | null
}

export const RadioSizes = ({item,onChange,filter,currentColor }: IProps) => {
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(Number(e.currentTarget.value))
    }
    return (
            <div className={'radio-sizes-container'}>
                <input type='radio'
                       className={'radio-size'}
                       value={item.id}
                       id={`${currentColor}-${item.id}`}
                       checked={item.id === filter}
                       onChange={onChangeHandler}/>
                <label className={'radio-label-size'} htmlFor={`${currentColor}-${item.id}`}>
                    {item.name.toUpperCase()}
                </label>
            </div>
    )
}
