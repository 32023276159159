import React from "react";
import './Basket.scss'
import {BasketItems} from "./components/basketItems/BasketItems";
import {BlackButton} from "../../components/buttons/blackButton/BlackButton";
import {Title} from "../../components/title/Title";
import {Address} from "./components/address/Address";
import {BasketColumns} from "./components/basketColumns/BasketColumns";
import {TEXT} from "./index";
import { IAddress } from "../../types/address";
import {ICart} from "../../types/cart";

interface IProps {
    totalCount: number
    carts: ICart[]
    defaultAddress: null | IAddress
    currentAddress: IAddress | null
    totalAmount: number | null
    showChangeAddressHandler: () => void
    showAddAddressHandler: () => void
    createOrder: () => void
}

export const Layout = (props: IProps) => {
    return (
            <div className={'basket-content'}>
                <div className={'basket-content-container'}>
                    <Title title={`${TEXT.TITLE} (${props.totalCount})`}/>
                    <BasketColumns/>
                </div>
                <BasketItems carts={props.carts}/>
                <div className={'basket-order-settings'}>
                    <Address defaultAddress={props.defaultAddress}
                             currentAddress={props.currentAddress}
                             showAddAddressHandler={props.showAddAddressHandler}
                             showChangeAddressHandler={props.showChangeAddressHandler}/>
                    <div className={'basket-order-checkout'}>
                        <Title title={`ИТОГО: ${props.totalAmount} BYN`}/>
                        <BlackButton type={"button"}
                                     title={TEXT.CREATE_ORDER_BUTTON}
                                     onClick={props.createOrder}/>
                    </div>
                </div>
            </div>
    )
}
