import React, {useEffect} from 'react'
import './Order.scss'
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getOrderTC} from "../../../redux/reducers/orderReducer";
import {RootStateType} from "../../../redux/store";
import {Title} from "../../../components/title/Title";
import {BackComponent} from "../../../components/backComponent/BackComponent";
import {config} from "../../../config/config";
import {dateFormat} from '../../../utils/dateUtils';
import {BlackButton} from "../../../components/buttons/blackButton/BlackButton";
import {IOrderDescription} from "../../../types/order";

export const Order = () => {
    const {orderUrlId}: any = useParams()
    const orderId = +orderUrlId
    const dispatch = useDispatch()
    const order = useSelector<RootStateType, IOrderDescription>(state => state.order.order)

    useEffect(() => {
        dispatch(getOrderTC(orderId))
    }, [dispatch, orderId])

    return (
            <div className={'order'}>
                <div className={'order-container'}>
                    <div className={'order-header-container'}>
                        <BackComponent/>
                        <Title title={`ЗАКАЗ № ${orderId}`}/>
                    </div>
                    <div className={'order-columns'}>
                        <div className={'order-column-title'}>
                            <div className={'order-column-title'}>
                                Время заказа:
                            </div>
                            <div className={'order-column-title order-text'}>
                                {dateFormat(order.createdDate)}
                            </div>
                        </div>
                        <div className={'order-column-title'}>
                            Статус заказа
                        </div>
                        <div className={'order-column-title'}>
                            Сумма
                        </div>
                    </div>

                    <div className={'order-items-container'}>
                        {
                            order?.items?.map(i => {
                                return (
                                        <div className={'order-items'}>
                                            <div className={'order-item-description-container'}>
                                                <img className={'order-item-img'}
                                                     alt={'item-img'}
                                                     src={config.baseImgPathUrl + i.webPath}/>

                                                <div className={'order-item-description'}>
                                                    <div className={'bold-text'}>
                                                        {i.name}
                                                    </div>

                                                    <div className={'order-item-description-title'}>
                                                        {`Цвет: ${i.colourName}`}
                                                    </div>
                                                    <div className={'order-item-description-title'}>
                                                        {`Размер: ${i.sizeName.toUpperCase()}`}
                                                    </div>
                                                    <div className={'order-item-description-title'}>
                                                        {`Артикул: ${i.orderPoint}`}
                                                    </div>
                                                    {/*TODO*/}
                                                    <div className={'order-item-description-title'}>
                                                        {`Количество: ${i.quantity}`}
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                {order.status}
                                            </div>
                                            <div className={'bold-text'}>
                                                {`${i.amount} BYN`}
                                            </div>
                                        </div>
                                )
                            })
                        }
                    </div>
                    <div className={'order-all-amount bold-text'}>
                        {`ИТОГО:  ${order.amount} BYN`}
                        {!order.status && <BlackButton type={"button"} target="_blank" to={`api/bepaid/${order.id}/pay`} isLink={true} title={"Оплатить"} />}
                    </div>
                    <div>

                    </div>


                </div>
            </div>
    )
}
