import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {Redirect} from "react-router-dom";
import {confirmEmailTC} from "../../redux/reducers/authReducer";
import {useQuery} from "../../hooks/useQuery";

export const ConfirmEmail = () => {
    const dispatch = useDispatch()
    const query = useQuery()
    const token = query.get("token")
    useEffect(() => {
        if(token){
            dispatch(confirmEmailTC(token))
        }
    },[dispatch, token])

    return <Redirect to={'/'}/>
};
