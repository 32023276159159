import {addressAPI} from "../../api/shop/addressAPI";
import {BaseThunkType, InferActionsType} from "../store";
import {commonThunkCreator} from "./commonReducer";
import {IAddress, INewAddress} from "../../types/address";

type ThunkType = BaseThunkType<IActions>;
export type IActions = InferActionsType<typeof addressActions>;
export type IAddressReducer = typeof initialState;

const initialState = {
    addresses: [] as IAddress[],
    currentAddressId: 1 as number,
    currentAddress: null as (null | IAddress),
    defaultAddress: null as IAddress | null,
}

export const addressReducer = (state = initialState, action: IActions): IAddressReducer => {
    switch (action.type) {
        case "SET_ADDRESSES":
            return {
                ...state,
                addresses: action.payload.addresses,
                defaultAddress: action.payload.addresses.find(i => i.defaultAddress) || null,

            }
        case "SET_CURRENT_ADDRESS":
            return {
                ...state,
                currentAddressId: action.payload.addressId,
                currentAddress: state.addresses.find(i => i.id === action.payload.addressId) || null
            }
        default:
            return state
    }
}

export const addressActions = {
    setCurrentAddressAC: (addressId: number) =>
        ({
            type: "SET_CURRENT_ADDRESS",
            payload: {addressId}
        } as const),
    setAddressesAC: (addresses: Array<IAddress>) =>
        ({
            type: "SET_ADDRESSES",
            payload: {addresses}
        } as const)
};

export const getAddressesTC = (): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const addresses = await addressAPI.getAddresses()
        dispatch(addressActions.setAddressesAC(addresses.data))
    }, dispatch);
};
export const addAddressTC = (newAddress: INewAddress): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await addressAPI.addAddress(newAddress)
        const addresses = await addressAPI.getAddresses()
        dispatch(addressActions.setAddressesAC(addresses.data))
    }, dispatch);
};
export const setDefaultAddressTC = (addressId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await addressAPI.setDefaultAddress(addressId)
        const addresses = await addressAPI.getAddresses()
        dispatch(addressActions.setAddressesAC(addresses.data))
    }, dispatch);
};


