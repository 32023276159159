import {sizeAPI} from "../../api/shop/sizeAPI";
import {BaseThunkType, InferActionsType} from "../store";
import {commonThunkCreator} from "./commonReducer";
import {ISizes} from "../../types/size";

type ThunkType = BaseThunkType<IActions>;
export type IActions = InferActionsType<typeof sizeActions>;
export type ISizeReducer = typeof initialState;

export type SizeReducerType = typeof initialState
const initialState = {
    sizes: [] as ISizes[],
}

export const sizeReducer = (state = initialState, action: IActions): ISizeReducer => {
    switch (action.type) {
        case "SET-SIZES":
            return {
                ...state,
                sizes: action.sizes
            }
        default:
            return state
    }
}

export const sizeActions = {
    setSizesAC: (sizes: ISizes[]) =>
        ({type: "SET-SIZES", sizes} as const)
};

export const getSizesTC = (categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const sizes = await sizeAPI.getSizes(categoryId)
        dispatch(sizeActions.setSizesAC(sizes.data.content))
    }, dispatch);
};
