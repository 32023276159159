import React from 'react'
import {googleConfig} from '../../config/config'
import './StaticsPage.scss'

export const AboutClub = () => {
    return (
            <div className={'page'}>
                <div className={'container'}>
                    <h2 className={'title'}>Вы точно найдете нас по этому адресу</h2>
                    <div className={'about-paragraph'}>
                        Клуб «RE:PUBLIC», Минск, ул. Притыцкого, д. 62, — мы находимся в 180 секундах ходьбы от метро
                        «Кунцевщина».
                    </div>
                    <iframe src={googleConfig.iframeURL}
                            className={'map'}
                            title={'map'}
                            width="100%"
                            height="300px"/>

                </div>
            </div>
    )
}
