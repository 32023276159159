import React from 'react'
import './BurgerItems.scss'
import {routes} from "../../../../navigation/constants"
import {NavLink} from "react-router-dom"
import {ReactComponent as RightArrow} from "../../../../assest/icons/arrow-right.svg"
import {BURGER_MENU_STATUS} from '../constants'
import {ICategory} from "../../../../types/category";
import {ReactComponent as CartIcon} from "../../../../assest/icons/basket.svg";

interface IProps {
    isAuth: boolean
    burgerStatus: BURGER_MENU_STATUS
    categories: ICategory[]
    showSubItemsHandler: () => void
    showSubItems: boolean
    changeBurgerStatus: () => void
}

export const BurgerItems = ({
                                isAuth,
                                changeBurgerStatus,
                                showSubItemsHandler,
                                showSubItems,
                                burgerStatus,
                                categories
                            }: IProps) => {

    if (burgerStatus === BURGER_MENU_STATUS.CLOSE) {
        return null
    }

    return (
            <div className={'burger-list-container'}>
                <div className={'burger-list-modal'}>
                    <div className={'burger-list-content'}>
                        <div onClick={changeBurgerStatus} className={'burger-close-button'}>
                            <span className={'burger-close-row'}/>
                            <span className={'burger-close-row'}/>
                        </div>
                        <div>
                            <div className={'burger-list-auth'}>
                                {!isAuth && routes.auth.map(i => {
                                    return <NavLink key={i.id}
                                                    className={'burger-item'}
                                                    to={i.path}
                                    >
                                        {i.title}
                                    </NavLink>
                                })
                                }
                            </div>

                            <div className={'burger-list-items'}>
                                <div onClick={showSubItemsHandler} className={'burger-item'}>
                                    <span>{routes.catalog.title}</span>
                                    <RightArrow/>
                                </div>
                                <div className={'burger-sub-items'}>
                                    {showSubItems && categories.map(category => {
                                        return <NavLink className={'burger-item'}
                                                        key={category.id}
                                                        to={`${routes.catalog.path}/${category.id}`}
                                        >
                                            {category.description}
                                        </NavLink>
                                    })}
                                </div>
                                {routes.header.map(i => {
                                    return <NavLink to={i.path}
                                                    className={'burger-item'}
                                    >
                                        <span>{i.title}</span>
                                        <RightArrow/>
                                    </NavLink>
                                })}
                            </div>
                        </div>



                            <NavLink to={'/basket'}
                                     className={'burger-basket-icon'}
                            >
                                <CartIcon/>
                            </NavLink>

                    </div>
                </div>
            </div>
    );
};
