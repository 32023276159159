import React, {useEffect} from 'react'
import {Redirect, Route} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from '../redux/store';
import {commonActions} from "../redux/reducers/commonReducer";

export const PrivateRoute = ({component, ...rest}: any) => {
    const dispatch = useDispatch();
    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)

    useEffect(() => {
        !isAuth && dispatch(commonActions.setErrorToastAC("Вы не авторизованы"))
    },[dispatch, isAuth])

    const routeComponent = (props: any) => (
            isAuth
                    ? React.createElement(component, props)
                    : <Redirect to={{pathname: '/login'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

