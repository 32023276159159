import React from 'react'
import './ButtonString.scss'

interface IProps {
    onClick: () => void
    title: string
}

export const ButtonString = ({onClick, title}: IProps) => {
    return (
            <button onClick={onClick} className={'button-string'}>
                {title}
            </button>
    )
}
