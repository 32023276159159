import {instance} from '../instance'
import {INewAddress} from "../../types/address";


export const addressAPI = {
    getAddresses() {
        return instance.get('address')
    },
    addAddress(newAddress: INewAddress) {
        return instance.post(`address`, null, {params: {...newAddress}})
    },
    setDefaultAddress(addressId: number) {
        return instance.post(`address/default`, null, {params: {addressId}})
    },

}
