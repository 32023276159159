import {instance} from '../instance'


export const itemsAPI = {
    getItems(categoryId: number) {
        return instance.get(`item?categoryId=${categoryId}&size=${1000}&paged=${1}`)
    },
    findItem(id: number) {
        return instance.get(`item/${id}`)
    },

}