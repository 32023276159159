import React, {ChangeEvent} from 'react'
import './ChangeItemsCount.scss'
import {TEXT} from "../../../../index";

interface IProps {
    itemCount: number
    decreaseHandler: () => void
    onBlurHandler: (count: number) => void
    onChangeItemCount: (count: number) => void
    increaseHandler: () => void
}

export const ChangeItemsCount = ({
                                     itemCount,
                                     decreaseHandler,
                                     onBlurHandler,
                                     onChangeItemCount,
                                     increaseHandler
                                 }: IProps) => {

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChangeItemCount(e.currentTarget.valueAsNumber)
    }
    const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
        onBlurHandler(e.currentTarget.valueAsNumber)
    }

    return (
            <div className={'basket-item-count-actions'}>
                <button disabled={itemCount <= 1}
                        onClick={decreaseHandler}
                        className={'count-action-button'}
                >
                    {TEXT.DECREASE}
                </button>
                <input className={'basket-item-input-action'}
                       onBlur={onBlur}
                       onChange={onChange}
                       min={1}
                       type={"number"} value={itemCount}
                />
                <button onClick={increaseHandler}
                        className={'count-action-button'}
                >
                    {TEXT.INCREASE}
                </button>
            </div>
    )
}
