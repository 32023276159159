import {authAPI} from "../../api/shop/authAPI"
import {commonActions, commonThunkCreator} from "./commonReducer"
import {BaseThunkType, InferActionsType} from "../store"
import {IAuthMeData} from "../../types/auth";
import {AUTH_ERRORS, TEXT} from "../../pages/auth";
import {getProfileInfoTC} from "./profileReducer";

type ThunkType = BaseThunkType<any>;
export type IActions = InferActionsType<typeof authActions>
export type IAuthReducer = typeof initialState;

const initialState = {
    isAuth: false as boolean,
    isInitialized: false as boolean,
    username: '' as string,
};

export const authReducer = (state = initialState, action: IActions): IAuthReducer => {
    switch (action.type) {
        case "SET_IS_AUTH_DATA":
            return {
                ...state,
                ...action.payload.isAuthData,
                isAuth: action.payload.isAuthData.auth,
                isInitialized: true
            }
        case "SET_USER":
            return {
                ...state,
            };
        default:
            return state;
    }
};

export const authActions = {
    setUserAC: () =>
        ({type: "SET_USER"} as const),
    setIsAuthDataAC: (isAuthData: IAuthMeData) =>
        ({type: "SET_IS_AUTH_DATA", payload: {isAuthData}} as const)
};


export const loginTC = (username: string, password: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const login = await authAPI.login(username, password);
        if (login.data.success === true) {
            dispatch(authMeTC())
            dispatch(commonActions.setIsShowModalAC(false))
        } else {
            dispatch(commonActions.setErrorToastAC(AUTH_ERRORS.INCORRECT_PASSWORD_OR_EMAIL))
        }
    }, dispatch);
};
export const registerTC = (email: string, password: string, recaptcha: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await authAPI.register(email, password, recaptcha);
        dispatch(commonActions.setSuccessToastAC(TEXT.CONFIRM_SEND_EMAIL))
        dispatch(commonActions.setIsShowModalAC(false))
    }, dispatch);
};
export const authMeTC = (): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const authMeData = await authAPI.authMe();
        dispatch(authActions.setIsAuthDataAC(authMeData.data));
    }, dispatch);
};

export const confirmEmailTC = (token: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await authAPI.confirmRegister(token);
        dispatch(commonActions.setSuccessToastAC(TEXT.CONFIRM_EMAIL_SUCCESS))
    }, dispatch);
};

export const changeEmailTC = (newEmail: string, password: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await authAPI.changeEmail(newEmail, password)
        dispatch(getProfileInfoTC())
    }, dispatch);
};

