import React from 'react'
import './Footer.scss'
import {NavLink} from "react-router-dom";
import {ReactComponent as Facebook} from '../../assest/icons/facebook.svg'
import {ReactComponent as Vk} from '../../assest/icons/vk.svg'
import {ReactComponent as Instagram} from '../../assest/icons/instagram.svg'

export const Footer = () => {
    return (
            <div className={'footer'}>
                <div className={'footer-container'}>
                    <div className={'footer-navigations'}>
                        <div className={'footer-work-mode'}>
                            <div className={'footer-navigation-title'}>
                                Режим работы:
                            </div>
                            <span className={'work-mode-text'}>
                                С 17.00 до 22.00 в в ни проведения концертов;
                            </span>
                            <span className={'work-mode-text'}>
                               с 23.00 до 06.00 в дни проведения дискотек.
                            </span>
                        </div>
                        <div className={'footer-clients'}>
                            <div className={'footer-navigation-title'}>
                                Клиентам:
                            </div>
                            <NavLink to={'/about'} className={'footer-link'}>
                                О нас
                            </NavLink>
                            <NavLink to={'/rules-pay'} className={'footer-link'}>
                                Правила оплаты
                            </NavLink>
                            <NavLink to={'/public-offer'} className={'footer-link'}>
                                Договор публичной оферты
                            </NavLink>
                        </div>
                        <div className={'footer-address'}>
                            <div className={'footer-navigation-title'}>
                                Адресс:
                            </div>
                            <span className={'address-text'}>
                                Минск ул. Притыцкого 62. ст. м. Кунцевщина
                            </span>
                            <span className={'phone-text'}>
                              Телефон: +375 29 110 35 35
                            </span>
                        </div>
                        <div className={'footer-social'}>
                            <div className={'footer-navigation-title'}>
                                Мы в социальных сетях:
                            </div>
                            <div>
                                <NavLink to={{pathname: 'https://www.facebook.com/RePub'}} target="_blank">
                                    <Facebook width={25} height={25}/>
                                </NavLink>
                                <NavLink to={{pathname: 'https://vk.com/republic_club '}} target="_blank">
                                    <Vk width={25}  height={25}/>
                                </NavLink>
                                <NavLink to={{pathname: 'https://www.instagram.com/republic.clubminsk/'}} target="_blank">
                                    <Instagram width={25} height={25}/>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className={'footer-images'}>
                        <a href="//bepaid.by" target="_blank" rel="noreferrer">
                            <img src="/assets/img/footer-belcard.png" className="image-belcard"
                                 alt="Поддерживаемые платёжные системы"/>
                        </a>
                        <a href="//mtbank.by" target="_blank" rel="noreferrer">
                            <img src="/assets/img/mtbank.png" className="image-mtbank" alt="Обслуживающий банк"/>
                        </a>
                    </div>
                    <div className={'footer-info'}>
                        <span className={'footer-text'}>
                           Индивидуальный предприниматель Старцев Андрей Валентинович
                            Свидетельство №190462465 выдано Минским горисполкомом 30 января 2015
                            Адрес: 220140 г. Минск, ул. Притыцкого, дом 62 - 109
                            УНП 190462465
                            Р/с 3013239400013 ЗАО «МТБанк», код 117
                            220033, г. Минск, пр. Партизанский, д. 6А
                            тел/факс +375 17 290 86 90
                            Все материалы © RE:PUBLIC. При цитировании ссылка на сайт обязательна.
                            © republic-club.by 2012–2021 г.
                        </span>
                    </div>
                </div>
            </div>
    )
}
