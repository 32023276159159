export enum AUTH_FORM_FIELDS {
    EMAIL = 'email',
    PASSWORD = 'password',
    ALL = 'all',
}

export enum TEXT {
    REGISTER_FROM_LOGIN_TEXT = 'У вас еще нет аккаунта?',
    REGISTER_FROM_LOGIN_LINK = 'Зарегистрироваться',
    LOGIN_BUTTON = 'ВОЙТИ',
    REGISTER_BUTTON = 'РЕГИСТРАЦИЯ',
    EMAIL = 'Email',
    PASSWORD = 'Пароль',
    CONFIRM_EMAIL_SUCCESS = 'Email успешно подтвержден',
    CONFIRM_SEND_EMAIL = 'Письмо для подтверждения выслано на почту',
}

export enum AUTH_ERRORS {
    INCORRECT_DATA = 'Введите корректные данные!',
    SHORT_PASSWORD = 'Пароль должен быть длиннее 3 символов!',
    INCORRECT_EMAIL = 'Некорректный email!',
    PRESS_CAPTCHA = 'Нажмите капчу',
    INCORRECT_PASSWORD_OR_EMAIL = 'Неправильно введен email или пароль',

}
