import React, {useEffect} from 'react';
import './Login.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store";
import {NavLink, Redirect, useLocation} from "react-router-dom";
import {AuthSocialLinks} from "../components/authSocialLinks/AuthSocialLinks";
import {LoginForm} from "./loginForm/LoginForm";
import {commonActions} from "../../../redux/reducers/commonReducer";
import {MODAL_WINDOWS} from "../../../components/modalWindow/ModalWindow";
import {TEXT} from "../index";

export const Login = () => {
    const dispatch = useDispatch()
    const location = useLocation<any>()
    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)
    useEffect(() => {
        dispatch(commonActions.setIsShowModalAC(true, MODAL_WINDOWS.LOGIN))
    }, [dispatch])

    useEffect(() => {
        isAuth && dispatch(commonActions.setIsShowModalAC(false))
    }, [dispatch, isAuth])

    if (isAuth) {
        return <Redirect to={'/'}/>
    }

    return (
            <div className={'login-modal-content'}>
                <AuthSocialLinks/>
                <LoginForm/>
                <div className={'login-modal-text'}>
                    {TEXT.REGISTER_FROM_LOGIN_TEXT}
                    <NavLink className={'register-link-text'}
                             to={{
                                 pathname: '/register',
                                 state: {background: location?.state?.background, fromLogin: true}
                             }}
                    >
                        {TEXT.REGISTER_FROM_LOGIN_LINK}
                    </NavLink>
                </div>
            </div>

    );
}
