import React from 'react';
import './InputWithPhoneMask.scss'
import {UseWindowDimensions} from "../../../hooks/useWindowDimensions";
import MaskedInput from "react-text-mask";

export const InputWithPhoneMask = ({
                                       field: {name, value, onChange},
                                       id,
                                       label,
                                       className,
                                       width,
                                       isBorder = false,
                                       ...props
                                   }: any) => {

    const windowDimensions = UseWindowDimensions()

    const phoneNumberMask = [
        "(",
        /[1-9]/,
        /[1-9]/,
        ")",
        " ",
        /[1-9]/,
        /[1-9]/,
        "-",
        /[1-9]/,
        /[1-9]/,
        "-",
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
    ];

    return (
            <MaskedInput
                    name={name}
                    mask={phoneNumberMask}
                    value={value}
                    onChange={onChange}
                    guide={false}
                    style={{width: width && windowDimensions.width > 770 ? width : "100%"}}
                    className={`${className} ${isBorder
                            ? 'text-input-component-with-border'
                            : 'text-input-component'}`}
                    {...props}
            />
    )
}
