import React from 'react'
import './UserInfo.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store";
import {BlackButton} from "../../../components/buttons/blackButton/BlackButton";
import {commonActions} from "../../../redux/reducers/commonReducer";
import {MODAL_WINDOWS} from "../../../components/modalWindow/ModalWindow";
import {IProfile} from "../../../types/profile";
import { transformBirtDay } from '../../../utils/dateUtils';

export const UserInfo = () => {
    const dispatch = useDispatch()
    const profile = useSelector<RootStateType, IProfile>(state => state.profile.profile)

    const showChangeUserInfoModal = () => {
        dispatch(commonActions.setIsShowModalAC(true, MODAL_WINDOWS.CHANGE_USER_INFO))
    }

    return (
            <div className={'user-info-container'}>
                <div className={'user-info-data'}>
                    <div className={'user-info-data-row'}>
                        <div className={'user-info-row-name'}>{`Фамилия`}</div>
                        <div className={'user-info-row-value'}>{profile.lastname}</div>
                    </div>
                    <div className={'user-info-data-row'}>
                        <div className={'user-info-row-name'}>{`Имя`}</div>
                        <div className={'user-info-row-value'}>{profile.firstname}</div>
                    </div>
                    <div className={'user-info-data-row'}>
                        <div className={'user-info-row-name'}>{`Телефон`}</div>
                        <div className={'user-info-row-value'}>{profile.phone}</div>
                    </div>
                    <div className={'user-info-data-row'}>
                        <div className={'user-info-row-name'}>{`Дата рождения`}</div>
                        <div className={'user-info-row-value'}>{transformBirtDay(profile.birthDate)}</div>
                    </div>
                    <div className={'user-info-data-row'}>
                        <div className={'user-info-row-name'}>{`E-mail`}</div>
                        <div className={'user-info-row-value'}>{profile.email}</div>
                    </div>
                </div>
                <BlackButton title={"РЕДАКТИРОВАТЬ"} type={"button"} onClick={showChangeUserInfoModal}/>
            </div>
    )
}
