import React, {useState} from 'react'
import './AddAdress.scss'
import {BlackButton} from '../../buttons/blackButton/BlackButton';
import {Field, Form, Formik} from "formik";
import {TextInput} from "../../textInput/TextInput";
import {useDispatch} from "react-redux";
import {addAddressTC} from '../../../redux/reducers/addressReducer';
import {commonActions} from "../../../redux/reducers/commonReducer";
import {InputWithPhoneMask} from "../../textInput/inputWithPhoneMask/InputWithPhoneMask";
import {onlyStringValidate} from "../../../utils/validate";
import {ADD_ADDRESS_FORM_FIELDS, MODAL_ERRORS, TEXT} from "../constants";
import {IAddressValues} from "../../../types/address";

export const AddAddress = () => {
    const dispatch = useDispatch()
    const [errorFields, setErrorFields] = useState<ADD_ADDRESS_FORM_FIELDS[]>([])
    const closeModal = () => {
        dispatch(commonActions.setIsShowModalAC(false))
    }
    const addAddressHandler = (values: IAddressValues) => {
        let description = ''
        if (values.street) {
            description = `ул: ${values.street}, `
        }
        if (values.home) {
            description = `${description} д: ${values.home}, `
        }
        if (values.flat) {
            description = `${description} кв: ${values.flat} `
        }
        const address = {
            city: values.city,
            country: values.country,
            description: description,
            phone: values.phone,
            state: values.state,
        }
        if (errorFields.length === 0) {
            dispatch(addAddressTC(address))
            dispatch(commonActions.setIsShowModalAC(false))
        }
    }

    const validate = (values: IAddressValues) => {
        const errors = []
        if (!values.home && !values.flat && !values.phone && !values.street && !values.city && !values.state) {
            dispatch(commonActions.setErrorToastAC(MODAL_ERRORS.INCORRECT_ALL_FIELDS))
            return setErrorFields([ADD_ADDRESS_FORM_FIELDS.ALL])
        }
        if (!values.home) {
            errors.push(ADD_ADDRESS_FORM_FIELDS.HOME)
        }
        if (!values.flat) {
            errors.push(ADD_ADDRESS_FORM_FIELDS.FLAT)
        }
        if (!values.phone || values.phone.length < 14) {
            errors.push(ADD_ADDRESS_FORM_FIELDS.PHONE)
        }
        if (!values.street) {
            errors.push(ADD_ADDRESS_FORM_FIELDS.STREET)
        }
        if (!values.city || onlyStringValidate(values.city)) {
            errors.push(ADD_ADDRESS_FORM_FIELDS.CITY)
        }

        if (!values.state || onlyStringValidate(values.state)) {
            errors.push(ADD_ADDRESS_FORM_FIELDS.STATE)
        }
        if (errors.length > 0) {
            dispatch(commonActions.setErrorToastAC(MODAL_ERRORS.INCORRECT_FORMAT))
        }
        setErrorFields(errors)
    }

    return (
            <div className={'add-address-body'}>
                <Formik
                        validate={validate}
                        validateOnChange={false}
                        initialValues={{
                            country: 'Беларусь',
                            state: '',
                            city: '',
                            street: '',
                            home: '',
                            flat: '',
                            phone: ''
                        }}
                        onSubmit={(values) => addAddressHandler(values)}
                >
                    <Form className={'add-address-form'}>
                        <div className={'add-address-fields'}>
                            <Field component={TextInput}
                                   placeholder={'Страна'}
                                   name={ADD_ADDRESS_FORM_FIELDS.COUNTRY}
                                   readOnly
                                   width={155}
                                   isBorder={true}
                            />
                            <Field component={TextInput}
                                   placeholder={'Край/Область/Регион*'}
                                   width={255}
                                   isBorder={true}
                                   name={ADD_ADDRESS_FORM_FIELDS.STATE}
                                   className={(errorFields.includes(ADD_ADDRESS_FORM_FIELDS.STATE) || errorFields.includes(ADD_ADDRESS_FORM_FIELDS.ALL)) && `error-input`}
                            />
                            <Field component={TextInput}
                                   placeholder={'Город*'}
                                   width={255}
                                   isBorder={true}
                                   name={ADD_ADDRESS_FORM_FIELDS.CITY}
                                   className={(errorFields.includes(ADD_ADDRESS_FORM_FIELDS.CITY) || errorFields.includes(ADD_ADDRESS_FORM_FIELDS.ALL)) && `error-input`}//className={(errorFields === ADD_ADDRESS_FORM_FIELDS.CITY || errorFields === ADD_ADDRESS_FORM_FIELDS.ALL) && `error-input`}
                            />
                            <Field component={TextInput}
                                   placeholder={'Улица*'}
                                   width={275}
                                   isBorder={true}
                                   name={ADD_ADDRESS_FORM_FIELDS.STREET}
                                   className={(errorFields.includes(ADD_ADDRESS_FORM_FIELDS.STREET) || errorFields.includes(ADD_ADDRESS_FORM_FIELDS.ALL)) && `error-input`}
                            />
                            <Field component={TextInput}
                                   placeholder={'Дом*'}
                                   width={110}
                                   isBorder={true}
                                   name={ADD_ADDRESS_FORM_FIELDS.HOME}
                                   className={(errorFields.includes(ADD_ADDRESS_FORM_FIELDS.HOME) || errorFields.includes(ADD_ADDRESS_FORM_FIELDS.ALL)) && `error-input`}
                            />
                            <Field component={TextInput}
                                   placeholder={'Квартира*'}
                                   width={125}
                                   isBorder={true}
                                   name={ADD_ADDRESS_FORM_FIELDS.FLAT}
                                   className={(errorFields.includes(ADD_ADDRESS_FORM_FIELDS.FLAT) || errorFields.includes(ADD_ADDRESS_FORM_FIELDS.ALL)) && `error-input`}
                            />
                            <Field component={InputWithPhoneMask}
                                   placeholder={'Номер телефона*'}
                                   isBorder={true}
                                   name={ADD_ADDRESS_FORM_FIELDS.PHONE}
                                   className={(errorFields.includes(ADD_ADDRESS_FORM_FIELDS.PHONE) || errorFields.includes(ADD_ADDRESS_FORM_FIELDS.ALL)) && `error-input`}
                            />
                        </div>
                        <div className={'add-address-buttons-container'}>
                            <BlackButton title={TEXT.SAVE_BUTTON} type={'submit'}/>
                            <BlackButton title={TEXT.CANCEL_BUTTON} type={'button'} onClick={closeModal}/>
                        </div>
                    </Form>
                </Formik>
            </div>
    )
}
