import {itemsAPI} from "../../api/shop/itemsAPI";
import {getColoursTC} from "./colourReducer";
import {commonThunkCreator} from "./commonReducer";
import {getItemPriceTC} from "./priceReducer";
import {getSizesTC} from "./sizeReducer";
import {BaseThunkType, InferActionsType} from "../store";
import {IItem, IItems, IPhotos} from "../../types/items";
import { IColour } from "../../types/colour";
import {ISizes} from "../../types/size";

type ThunkType = BaseThunkType<IActions>;
export type IActions = InferActionsType<typeof itemsActions>;
export type IItemsReducer = typeof initialState;



export type IItemInitialState = typeof initialState
const initialState = {
    items: {},
    item: {} as IItem,
    allImages: [] as IPhotos[],
    filteredImages: [] as IPhotos[],
    currentImage: null as (string | null),

    uniqItemColours: [] as IColour[],
    currentColor: null as (number | null),
    currentColorName: null as (string | null),

    currentSize: null as (number | null),
    uniqItemSizes: [] as ISizes[],
}

export const itemsReducer = (state = initialState, action: IActions): IItemsReducer => {
    switch (action.type) {
        case "SET_ITEMS":
            return {
                ...state,
                items: {...state.items, [action.payload.categoryId]: action.payload.items}
            }
        case "SET_ITEM":
            return {
                ...state,
                item: action.payload.item
            }
        case "SET_CURRENT_IMAGE":
            return {
                ...state,
                currentImage: action.payload.currentImage
            }
        case "SET_ALL_IMAGES_FOR_ITEM":
            const allImages = action.payload.item?.itemDetails.map(i => i.photos).flat() || []
            return {
                ...state,
                allImages: allImages,
                currentImage: allImages[0]?.path
            }
        case "SET_CURRENT_COLOR":
            const filteredImages = state.item.itemDetails.filter(i => action.payload.colourId === i.colour.id).map(i => i.photos).flat()
            return {
                ...state,
                currentColor: action.payload.colourId,
                currentColorName: state.uniqItemColours.find(i => i.id === action.payload.colourId)?.name || null,
                filteredImages: action.payload.colourId ? filteredImages : [],
                currentImage: action.payload.colourId ? filteredImages[0].path : state.allImages[0].path
            }
        case "SET_CURRENT_SIZE":
            return {
                ...state,
                currentSize: action.payload.sizeId
            }
        case "SET_UNIQ_ITEM_COLORS":
            return {
                ...state,
                uniqItemColours: action.payload.uniqItemColours
            }
        case "SET_UNIQ_ITEM_SIZES":
            return {
                ...state,
                uniqItemSizes: action.payload.uniqItemSizes
            }
        default:
            return state
    }
}

export const itemsActions = {
    setItemsAC: (items: Array<IItems>, categoryId: number) =>
        ({type: "SET_ITEMS", payload: {items, categoryId}} as const),
    setItemAC: (item: IItem) =>
        ({type: "SET_ITEM", payload: {item}} as const),
    setCurrentImageAC: (currentImage: string) =>
        ({type: "SET_CURRENT_IMAGE", payload: {currentImage}} as const),
    setAllImagesForItemAC: (item: IItem | null) =>
        ({type: 'SET_ALL_IMAGES_FOR_ITEM', payload: {item}} as const),
    setCurrentColorAC: (colourId: number | null) =>
        ({type: 'SET_CURRENT_COLOR', payload: {colourId}} as const),
    setCurrentSizeAC: (sizeId: number | null) =>
        ({type: 'SET_CURRENT_SIZE', payload: {sizeId}} as const),
    setUniqItemColorsAC: (uniqItemColours: IColour[]) =>
        ({type: 'SET_UNIQ_ITEM_COLORS', payload: {uniqItemColours}} as const),
    setUniqItemSizesAC: (uniqItemSizes: ISizes[]) =>
        ({type: 'SET_UNIQ_ITEM_SIZES', payload: {uniqItemSizes}} as const),
};

export const setCurrentItemSizes = (colourId: number | null) => (dispatch: any, getState: any) => {
    const state = getState();
    const sizesId = state.prices.prices.filter((p: any) => p.colourId === colourId).map((p: any) => p.sizeId)
    const uniqSizesId = sizesId.filter((item: any, pos: any) => sizesId.indexOf(item) === pos)
    const uniqItemSizes = state.sizes.sizes.filter((product: any) => uniqSizesId.find((i: any) => i === product.id))
    dispatch(itemsActions.setCurrentColorAC(colourId));
    dispatch(itemsActions.setUniqItemSizesAC(uniqItemSizes));
    dispatch(itemsActions.setCurrentSizeAC(null));
}
export const setCurrentItemColors = () => (dispatch: any, getState: any) => {
    const state = getState()
    const colorsId = state.prices.prices?.map((p: any) => p.colourId)
    const uniqColoursId = colorsId.filter((item: any, pos: any) => colorsId.indexOf(item) === pos)
    const uniqItemColours = state.colours.colours.filter((product: any) => uniqColoursId.find((i: any) => i === product.id))
    dispatch(itemsActions.setUniqItemColorsAC(uniqItemColours))
}


export const getItemsTC = (categoryId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const items = await itemsAPI.getItems(categoryId)
        dispatch(itemsActions.setItemsAC(items.data.content, categoryId))
    }, dispatch);
};
export const getDataForItemTC = (id: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const item = await itemsAPI.findItem(id)
        await dispatch(getItemPriceTC(id))
        await dispatch(getColoursTC(item.data.categoryId))
        await dispatch(getSizesTC(item.data.categoryId))
        dispatch(itemsActions.setItemAC(item.data))
        dispatch(itemsActions.setCurrentImageAC(item.data.webPath))
        dispatch(itemsActions.setAllImagesForItemAC(item.data))
        dispatch(setCurrentItemColors())
    }, dispatch);
};
