import React, {useEffect} from "react"
import './Main.scss'
import {NavLink} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {RootStateType} from "../../redux/store"
import {getCategoriesTC} from "../../redux/reducers/categoryReducer"
import noPhoto from '../../assest/images/no-photo.png'
import {config} from "../../config/config";
import {ICategory} from "../../types/category"

export const Main = () => {
    const recomendations = [
        {name: 'aaaaaa', id: 1, webPath: ''},
        {name: 'bbbbbb', id: 2, webPath: null},
        {name: 'aaaaaa', id: 3, webPath: null},
        {name: 'bbbbbb', id: 4, webPath: null},
        {name: 'aaaaaa', id: 5, webPath: null},
        {name: 'bbbbbb', id: 6, webPath: null},
        {name: 'aaaaaa', id: 7, webPath: null},
        {name: 'bbbbbb', id: 8, webPath: null},
    ]
    const dispatch = useDispatch()
    const categories = useSelector<RootStateType, ICategory[]>(state => state.categories.categories)

    useEffect(() => {
        dispatch(getCategoriesTC())
    }, [dispatch])

    return (
            <section className={'main-page'}>
                <div className={'title'}>
                    БУДЬ В СТИЛЕ RE:PUBLIC
                </div>
                <div className={'categories-container'}>
                    {
                        categories.map(c => {
                            return (
                                    <div key={c.id} className={'category-item'}>
                                        <NavLink to={`/category/${c.id}`} className={'category-link'}>
                                            <img className={'category-img'} alt={'category'}
                                                 src={c.webPath ? `${config.baseImgPathUrl}${c.webPath}` : noPhoto}/>
                                            <div className={'category-title'}>
                                                {c.name}
                                            </div>
                                        </NavLink>
                                    </div>
                            )
                        })
                    }
                </div>
                {/*<div className={'title'}>*/}
                {/*    РЕКОМЕНДУЕМЫЕ ТОВАРЫ*/}
                {/*</div>*/}
                {/*<div className={'recommendations-container'}>*/}
                {/*    {*/}
                {/*        recomendations.map(i => {*/}
                {/*            return (*/}
                {/*                    <div key={i.id} className={'recommendations-item'}>*/}
                {/*                        <img className={'recommendations-img'} alt={'recommendation'}*/}
                {/*                             src={i.webPath ? i.webPath : noPhoto}/>*/}
                {/*                        <div className={'recommendations-title'}>*/}
                {/*                            {i.name}*/}
                {/*                        </div>*/}
                {/*                        <div className={'recommendations-price'}>*/}
                {/*                            45р.*/}
                {/*                        </div>*/}
                {/*                        <NavLink key={i.id} to={`/category/${i.id}`}*/}
                {/*                                 className={'recommendations-link-button'}>*/}
                {/*                            Купить*/}
                {/*                        </NavLink>*/}
                {/*                    </div>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*</div>*/}

            </section>

    )
}
