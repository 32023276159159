import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import './Profile.scss'
import {getProfileInfoTC} from "../../redux/reducers/profileReducer";
import {BackComponent} from "../../components/backComponent/BackComponent";
import {Title} from "../../components/title/Title";
import profileIcon from '../../assest/icons/profile.png'
import {NavLink, Switch} from "react-router-dom";
import {Addresses} from './addresses/Addresses';
import {UserInfo} from './userInfo/UserInfo';
import {OrderList} from './orderList/OrderList';
import {PrivateRoute} from '../../utils/PrivateRoute';

export enum PROFILE_LINKS {
    PERSONAL_DATA = 'ПЕРСОНАЛЬНЫЕ ДАННЫЕ',
    ADDRESSES = 'АДРЕС ДОСТАВКИ',
    ALL_ORDERS = 'ВСЕ ЗАКАЗЫ',
    SIGN_UP = 'ВЫЙТИ',
}

export const Profile = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getProfileInfoTC())
    }, [dispatch])

    return (

            <div className={'profile-container'}>
                <div className={'profile-container-header'}>
                    <BackComponent/>
                    <div className={'profile-container-header-title'}>
                        <Title title={'ЛИЧНЫЙ КАБИНЕТ'}/>
                        <img className={'profile-title-icon'} alt={'profile'} src={profileIcon}/>
                    </div>
                </div>
                <div className={'profile-content-container'}>
                    <div className={'profile-tab'}>
                        <NavLink activeClassName={'active-profile-tab-link'} className={'profile-tab-link'}
                                 to={`/profile/info`}>{PROFILE_LINKS.PERSONAL_DATA}</NavLink>
                        <NavLink activeClassName={'active-profile-tab-link'} className={'profile-tab-link'}
                                 to={`/profile/addresses`}>{PROFILE_LINKS.ADDRESSES}</NavLink>
                        <NavLink activeClassName={'active-profile-tab-link'} className={'profile-tab-link'}
                                 to={`/profile/orders`}>{PROFILE_LINKS.ALL_ORDERS}</NavLink>
                        <div className={'profile-tab-link'} onClick={() => window.location.href = '/logout'}>
                            {PROFILE_LINKS.SIGN_UP}
                        </div>
                    </div>
                    <div className={'profile-tab-content'}>
                        <Switch>
                            <PrivateRoute component={UserInfo} path={'/profile/info'}/>
                            <PrivateRoute component={Addresses} exact path={'/profile/addresses'}/>
                            <PrivateRoute component={OrderList} exact path={'/profile/orders'}/>
                        </Switch>
                    </div>
                </div>
            </div>
    )
}
