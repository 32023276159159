export enum ADD_ADDRESS_FORM_FIELDS {
    COUNTRY = 'country',
    CITY = 'city',
    STREET = 'street',
    HOME = 'home',
    STATE = 'state',
    FLAT = 'flat',
    PHONE = 'phone',
    ALL = 'all',
}

export enum CHANGE_USER_INFO_FIELDS {
    LASTNAME = 'lastname',
    FIRSTNAME = 'firstname',
    PHONE = 'phone',
    BIRTH_DAY = 'birthDate',
    ALL = 'all'
}

export enum TEXT  {
    SAVE_BUTTON = 'Сохранить',
    CANCEL_BUTTON = 'Отмена',
}


export enum MODAL_ERRORS {
    INCORRECT_FORMAT = 'Неверный формат',
    INCORRECT_ALL_FIELDS = 'Все поля должны быть заполнены!',
}
