import React from 'react'
import './ItemColors.scss'
import {RadioColors} from "../../../../components/radioColors/RadioColors"
import {IColour} from "../../../../types/colour";

interface IProps {
    changeCurrentColorHandler: (value: number) => void
    currentColor: number | null
    uniqItemColours: IColour[]
    currentColorName: string | null
}

export const ItemColors = ({changeCurrentColorHandler, currentColor, uniqItemColours, currentColorName}: IProps) => {
    return (
            <div className={'item-colors-container'}>
                <div className={'item-colors-description'}>
                    {`Цвет: ${currentColorName || "Выберите цвет"}`}
                </div>
                <div className={'item-colors'}>
                    {uniqItemColours.map(item =>
                            <RadioColors key={item.id}
                                         filter={currentColor}
                                         onChange={changeCurrentColorHandler}
                                         item={item}
                            />
                    )}
                </div>
            </div>
    )
}
