import React from 'react'
import "./ItemImages.scss"
import 'pure-react-carousel/dist/react-carousel.es.css'
import {MainImage} from "./mainImage/MainImage"
import {SlideImages} from "./slideImages/SlideImages"
import {IPhotos} from "../../../types/items";

interface IProps {
    changeCurrentImageHandler: (value: string) => void
    currentImage: string | null
    allImages: IPhotos[]
    filteredImages: IPhotos[]
    currentColor: number | null
}

export const ItemImages = ({
                               changeCurrentImageHandler,
                               currentImage,
                               allImages,
                               filteredImages,
                               currentColor
                           }: IProps) => {
    return (
            <div className={'images'}>
                <SlideImages currentColor={currentColor}
                             filteredImages={filteredImages}
                             allImages={allImages}
                             changeCurrentImageHandler={changeCurrentImageHandler}
                />
                <MainImage currentImage={currentImage}/>

            </div>
    )
}
