import {instanceEmptyPath} from "../instance";

export const authAPI = {
    login(username: string, password: string) {
        const data = new FormData()
        data.append('username', username)
        data.append('password', password)
        return instanceEmptyPath.post("/api/auth/login", data)
    },
    authMe() {
        return instanceEmptyPath.get("/api/auth/user")
    },
    register(email: string, password: string, recaptcha: string) {
        const data = new FormData()
        data.append('email', email)
        data.append('password', password)
        data.append('g-recaptcha-response', recaptcha)
        return instanceEmptyPath.post(`/api/auth/create-account`, data)
    },
    confirmRegister(token: string) {
        return instanceEmptyPath.post("/api/auth/confirmation-of-registration", null, {params: {token}})
    },
    changeEmail(newEmail: string, password: string) {
        return instanceEmptyPath.post(`api/profile/email?password=${password}`, 'jleninprolol@gmail.com')
    },
};
