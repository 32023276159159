const mounts = [
    'Янвавря',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
]

export const dateFormat = (date: string) => {
    const transformDate = new Date(date).toLocaleString('ru-Latn')
    const dateToArr = transformDate.toString().split('.')
    dateToArr[1] = mounts[+dateToArr[1] - 1]
    return dateToArr.join(' ')
}

export const transformBirtDay = (date: string) => {
    const newDate = date?.split('-')
    return newDate ? `${newDate[2]}-${newDate[1]}-${newDate[0]}` : ""
}
