import React, {useState} from 'react';
import './LoginForm.scss'
import {Field, Form, Formik} from "formik";
import {commonActions} from "../../../../redux/reducers/commonReducer";
import {loginTC} from "../../../../redux/reducers/authReducer";
import {useDispatch} from "react-redux";
import {TextInput} from '../../../../components/textInput/TextInput';
import {BlackButton} from "../../../../components/buttons/blackButton/BlackButton";
import {AUTH_ERRORS, AUTH_FORM_FIELDS, TEXT} from '../../index';
import {ILoginForm} from "../../../../types/auth";

export const LoginForm = () => {
    const [errorFields, setErrorFields] = useState<AUTH_FORM_FIELDS | null>()
    const dispatch = useDispatch()

    const onSubmit = (values: ILoginForm) => {
        if (!errorFields) {
            dispatch(loginTC(values.email, values.password))
            values.password = ''
        }
    }
    const validate = (values: ILoginForm) => {
        if (!values.password && !values.email) {
            dispatch(commonActions.setErrorToastAC(AUTH_ERRORS.INCORRECT_DATA))
            setErrorFields(AUTH_FORM_FIELDS.ALL)
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            dispatch(commonActions.setErrorToastAC(AUTH_ERRORS.INCORRECT_EMAIL))
            setErrorFields(AUTH_FORM_FIELDS.EMAIL)
        } else if (values.password.length < 3) {
            dispatch(commonActions.setErrorToastAC(AUTH_ERRORS.SHORT_PASSWORD))
            setErrorFields(AUTH_FORM_FIELDS.PASSWORD)
        } else {
            setErrorFields(null)
        }
    }
    return (
            <Formik
                    validate={validate}
                    validateOnChange={false}
                    initialValues={{email: '', password: '',}}
                    onSubmit={(values) => onSubmit(values)}
            >
                <Form className={'login-modal-form'}>
                    <Field component={TextInput}
                           className={(errorFields === AUTH_FORM_FIELDS.EMAIL || errorFields === AUTH_FORM_FIELDS.ALL) && `error-input`}
                           placeholder={TEXT.EMAIL}
                           name={AUTH_FORM_FIELDS.EMAIL}
                    />
                    <Field component={TextInput}
                           className={(errorFields === AUTH_FORM_FIELDS.PASSWORD || errorFields === AUTH_FORM_FIELDS.ALL) && `error-input`}
                           placeholder={TEXT.PASSWORD}
                           type={'password'}
                           name={AUTH_FORM_FIELDS.PASSWORD}
                    />
                    <BlackButton title={TEXT.LOGIN_BUTTON} type={'submit'}/>
                </Form>
            </Formik>
    );
};
