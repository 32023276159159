import React from 'react'
import './StaticsPage.scss'

export const RulesPay = () => {
    return (
            <div className={'page'}>
                <div className={'container'}>
                    <h2 className={'title'}>«Оплатить выбранные товары вы можете следующими способами»</h2>
                    <div className={'paragraph'}>
                        Дорогие Друзья!
                    </div>
                    <div className={'paragraph'}>
                        На платежной странице будет указан номер заказа и сумма платежа. Для оплаты вам необходимо ввести свои карточные данные и подтвердить платеж, нажав кнопку «Оплатить». Если ваша карта поддерживает технологию 3-D Secure, системой ваш будет предложено пройти стандартную одноминутную процедуру проверки владельца карты на странице вашего банка (банка, который выдал вашу карту).
                        Обращаем ваше внимание, что после проведения платежа на указанный вами электронный адрес придет подтверждение оплаты. Просим вас сохранять данные оплат.
                        Мы принимаем платежи по следующим банковским картам: Visa, Visa Electron, MasterCard, Maestro, Белкарт и картой Халва.
                    </div>
                    <div className={'paragraph'}>
                        Платежи по банковским картам осуществляются через систему электронных платежей bePaid.  Платежная страница системы bePaid отвечает всем требованиям безопасности передачи данных (PCI DSS Level 1). Все конфиденциальные данные хранятся в зашифрованном виде и максимально устойчивы к взлому.
                        Возврат денежных средств осуществляется на карту, с которой ранее была произведена оплата. Срок поступления денежных средств на карту от 3 до 30 дней с момента осуществления возврата Продавцом.
                        Возврат возможен, если до мероприятия не менее 5 рабочих дней. Для возврата напишите в виджет ВК и опишите проблему.
                        Заказ, оплаченный на сайте Вы можете получить в клубе РеПаблик, по адресу г. Минск ул. Притыцкого, д. 62.
                    </div>
                    <div className={'paragraph'}>
                        Для получения заказа Вы заполняете все поля формы, в данном разделе : список позиций меню и информацию о себе.
                    </div>
                </div>
            </div>
    )
}
