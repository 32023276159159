import {InferActionsType} from "../store";
import {toast} from "react-toastify";

export type IActions = InferActionsType<typeof commonActions>;
export type ICommonReducer = typeof initialState;
export type ICommonInitialState = typeof initialState

const initialState = {
    isLoading: false,
    isShowModal: false as boolean,
    componentName: null as (string | null)
};

export const commonReducer = (state = initialState, action: IActions): ICommonReducer => {
    switch (action.type) {
        case "SET_IS_LOADING":
            return {
                ...state,
                ...action.payload
            }
        case "SET-IS-SHOW_MODAL":
            return {
                ...state,
                isShowModal: action.payload.isShowModal,
                componentName: action.payload.componentName || null
            }
        case "SET_SUCCESS_TOAST":
            toast.success(action.payload.message);
            return {
                ...state
            }
        case "SET_ERROR_TOAST":
            toast.error(action.payload.message);
            return {
                ...state
            }
        case "SET_INFO_TOAST":
            toast.info(action.payload.message);
            return {
                ...state
            }
        case "SET_WARNING_TOAST":
            toast.warning(action.payload.message);
            return {
                ...state
            }

        default:
            return state;
    }
};

export const commonActions = {
    setIsShowModalAC: (isShowModal: boolean, componentName?: string) => ({
        type: 'SET-IS-SHOW_MODAL',
        payload: {isShowModal, componentName}
    } as const),
    setIsFetchingAC: (isLoading: boolean) => ({type: 'SET_IS_LOADING', payload: {isLoading}} as const),
    setSuccessToastAC: (message: string) => ({type: 'SET_SUCCESS_TOAST', payload: {message}} as const),
    setErrorToastAC: (message: string) => ({type: 'SET_ERROR_TOAST', payload: {message}} as const),
    setWarningToastAC: (message: string) => ({type: 'SET_WARNING_TOAST', payload: {message}} as const),
    setInfoToastAC: (message: string) => ({type: 'SET_INFO_TOAST', payload: {message}} as const),
};

export const commonThunkCreator = (operation: any, dispatch: any) => {
    let tryCathed = withTryCath(operation, dispatch);
    let isFetching = withIsFetching(tryCathed, dispatch);
    return isFetching();
};

export const withTryCath = (operation: any, dispatch: any) => {
    return async () => {
        try {
            return await operation();
        } catch (e: any) {
            if(e.response.data.error === true){
                dispatch(commonActions.setErrorToastAC(`${e.response.data.message}`))
            } else {
                dispatch(commonActions.setErrorToastAC(`Что то пошло не так`))
            }
        }
        return null;
    };
};

export const withIsFetching = (operation: any, dispatch: any) => {
    return async () => {
        dispatch(commonActions.setIsFetchingAC(true));
        await operation();
        dispatch(commonActions.setIsFetchingAC(false));
    };
};
