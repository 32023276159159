import React from 'react';
import './AuthSocialLinks.scss'
import google from "../../../../assest/images/go.png";
import github from "../../../../assest/images/gh.png";
import {NavLink} from 'react-router-dom';

export const AuthSocialLinks = () => {
    return (
            <div className={'auth-social-links'}>
                <NavLink className={'auth-social-link'}
                         to={"/oauth2/authorization/google"}
                         target="_blank"
                >
                    <img alt={'google'}
                         src={google}
                         className={'auth-social-link-image'}
                    />
                </NavLink>
                <NavLink className={'auth-social-link'}
                         to={"/oauth2/authorization/github"}
                         target="_blank"
                >
                    <img alt={'github'}
                         src={github}
                         className={'auth-social-link-image'}
                    />
                </NavLink>
            </div>
    );
};
