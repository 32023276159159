import React from 'react'
import {useLocation} from 'react-router-dom'
import './Header.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store";
import {commonActions} from "../../redux/reducers/commonReducer";
import {MODAL_WINDOWS} from "../../components/modalWindow/ModalWindow";
import {MenuLinks} from "./menuLinks/MenuLinks";
import {AuthLinks} from "./authLinks/AuthLinks";
import {LoggedInLinks} from "./loggedInLinks/LoggedInLinks";
import {Burger, RepublicLogo} from "../../components";

export const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch()

    const isAuth = useSelector<RootStateType, boolean>(state => state.auth.isAuth)
    const cartCount = useSelector<RootStateType, number>(state => state.carts.totalCount)
    const showModal = (modalName?: MODAL_WINDOWS) => {
        dispatch(commonActions.setIsShowModalAC(true, modalName))
    }
    return (
            <header className={'header'}>
                <div className={'header-container'}>
                    <RepublicLogo/>
                    <div className={'links'}>
                        <MenuLinks/>
                        <AuthLinks
                                isAuth={isAuth}
                                showModal={showModal}
                                location={location}
                        />
                    </div>
                    <LoggedInLinks cartCount={cartCount} isAuth={isAuth}/>
                    <Burger />
                </div>
            </header>
    )
}
