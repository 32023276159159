import React, {useEffect, useRef, useState} from 'react';
import './RegisterForm.scss'
import {useDispatch} from "react-redux";
import {registerTC} from "../../../../redux/reducers/authReducer";
import {commonActions} from "../../../../redux/reducers/commonReducer";
import {Field, Form, Formik} from "formik";
import {TextInput} from "../../../../components/textInput/TextInput";
import {BlackButton} from "../../../../components/buttons/blackButton/BlackButton";
import {AUTH_ERRORS, AUTH_FORM_FIELDS, TEXT} from "../../index";
import {validateEmail} from "../../../../utils/validate";
import {IRegisterForm} from "../../../../types/auth";
import {googleConfig} from "../../../../config/config";
import ReCAPTCHA from "react-google-recaptcha";

export const RegisterForm = () => {
    const [errorFields, setErrorFields] = useState<AUTH_FORM_FIELDS | null>()
    const [captcha, setCaptcha] = useState<string | null>(null)
    const dispatch = useDispatch()
    const recaptchaRef = useRef<any>()

    useEffect(() => {
        const script = document.createElement("script")
        script.src = googleConfig.captchaURL
        script.id = "qwerty"
        script.async = true
        script.defer = true
        document.body.appendChild(script)
        return () => setCaptcha(null)
    }, [])
    function onChange(value: any) {
        setCaptcha(value)
    }
    const onSubmit = (values: IRegisterForm) => {
        if (!errorFields && captcha) {
            dispatch(registerTC(values.email, values.password, captcha))
            values.password = ''
            setCaptcha(null)
        }
    }
    const validate = (values: IRegisterForm) => {
        if (!values.password && !values.email) {
            dispatch(commonActions.setErrorToastAC(AUTH_ERRORS.INCORRECT_DATA))
            setErrorFields(AUTH_FORM_FIELDS.ALL)
        } else if (validateEmail(values.email)) {
            dispatch(commonActions.setErrorToastAC(AUTH_ERRORS.INCORRECT_EMAIL))
            setErrorFields(AUTH_FORM_FIELDS.EMAIL)
        } else if (values.password.length < 3) {
            dispatch(commonActions.setErrorToastAC(AUTH_ERRORS.SHORT_PASSWORD))
            setErrorFields(AUTH_FORM_FIELDS.PASSWORD)
        } else if (!captcha) {
            dispatch(commonActions.setErrorToastAC(AUTH_ERRORS.PRESS_CAPTCHA))
        } else {
            setErrorFields(null)
        }
    }
    return (
            <Formik
                    validate={validate}
                    validateOnChange={false}
                    initialValues={{email: '', password: '', recaptcha: '',}}
                    onSubmit={(values) => onSubmit(values)}
            >
                <Form className={'register-modal-form'}>
                    <Field component={TextInput}
                           className={(errorFields === AUTH_FORM_FIELDS.EMAIL || errorFields === AUTH_FORM_FIELDS.ALL) && `error-input`}
                           placeholder={TEXT.EMAIL}
                           name={AUTH_FORM_FIELDS.EMAIL}
                    />
                    <Field component={TextInput}
                           className={(errorFields === AUTH_FORM_FIELDS.PASSWORD || errorFields === AUTH_FORM_FIELDS.ALL) && `error-input`}
                           placeholder={TEXT.PASSWORD}
                           type={"password"}
                           name={AUTH_FORM_FIELDS.PASSWORD}
                    />
                    <div className={"register-captcha"}>
                        <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={googleConfig.captchaSiteKey}
                                onChange={onChange}
                        />
                    </div>
                    <BlackButton title={TEXT.REGISTER_BUTTON} type={'submit'}/>
                </Form>
            </Formik>
    );
};
