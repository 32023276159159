import React from 'react'
import './ItemSizes.scss'
import {RadioSizes} from "../../../../components/radioSizes/RadioSizes"
import { ISizes } from '../../../../types/size'

interface IProps {
    changeCurrentSizeHandler: (value: number) => void
    currentSize: number | null
    currentColor: number | null
    uniqItemSizes: ISizes[]
}

export const ItemSizes = ({changeCurrentSizeHandler, currentColor, uniqItemSizes, currentSize}: IProps) => {
    return (
            <div className={'item-sizes-container'}>
                <div className={'item-sizes-description'}>

                    {currentColor && `Размер: ${currentSize ? "" : "Выберите размер"}`}
                </div>
                <div className={'item-sizes'}>
                    {uniqItemSizes.map(item =>
                            <RadioSizes key={item.id}
                                        currentColor={currentColor}
                                        filter={currentSize}
                                        onChange={changeCurrentSizeHandler}
                                        item={item}
                            />
                    )}
                </div>
            </div>
    )
}
