import React from 'react'
import './ItemSettings.scss'
import {ItemColors} from "./itemColors/ItemColors"
import {ItemSizes} from './itemSizes/ItemSizes'
import {BlackButton} from "../../../components/buttons/blackButton/BlackButton"
import {Title} from "../../../components/title/Title"
import {IItem} from "../../../types/items";
import {IColour} from "../../../types/colour";
import {IItemPrice} from "../../../types/price";
import { ISizes } from '../../../types/size'

interface IProps {
    changeCurrentColorHandler: (value: number) => void
    currentItemPrice?: IItemPrice
    addBasket: () => void
    item: IItem
    changeCurrentSizeHandler: (value: number) => void
    currentSize: number | null
    currentColor: number | null
    uniqItemSizes: ISizes[]
    uniqItemColours: IColour[]
    currentColorName: string | null
}

export const ItemSettings = ({
                                 currentItemPrice,
                                 changeCurrentColorHandler,
                                 changeCurrentSizeHandler,
                                 currentColor,
                                 addBasket,
                                 uniqItemSizes,
                                 currentSize,
                                 uniqItemColours,
                                 currentColorName,
                                 item
                             }: IProps) => {
    return (
            <div className={'item-main'}>
                <div className={'item-main-container'}>
                    <Title title={item.name} />
                    <div className={'item-main-price'}>
                        {currentItemPrice?.amount && `${currentItemPrice?.amount} BYN`}
                    </div>
                    <ItemColors currentColorName={currentColorName}
                                uniqItemColours={uniqItemColours}
                                currentColor={currentColor}
                                changeCurrentColorHandler={changeCurrentColorHandler}

                    />
                    <ItemSizes uniqItemSizes={uniqItemSizes}
                               currentColor={currentColor}
                               changeCurrentSizeHandler={changeCurrentSizeHandler}
                               currentSize={currentSize}
                    />
                    <BlackButton type={'button'} onClick={addBasket} title={'ДОБАВИТЬ В КОРЗИНУ'}/>
                    <div className={'item-main-description-container'}>
                        <span className={'item-subtitle'}>Описание:</span>
                        <span className={'item-main-description'}>{item.description}</span>
                    </div>
                </div>
            </div>
    )
}
