import axios from "axios";

export const instance = axios.create({
    withCredentials: true,
    baseURL: "/api/shop/"
})

export const instanceEmptyPath = axios.create({
    withCredentials: true,
    baseURL: "/",
});
