import {cartAPI} from "../../api/shop/cartAPI";
import { ICart } from "../../types/cart";
import {BaseThunkType, InferActionsType} from "../store";
import {commonThunkCreator} from "./commonReducer";

type ThunkType = BaseThunkType<IActions>;
export type IActions = InferActionsType<typeof cartActions>
export type ICartReducer = typeof initialState;

const initialState = {
    carts: [] as ICart[],
    totalCount: 0 as number,
    totalAmount: 0 as number,
}

export const cartReducer = (state = initialState, action: IActions): ICartReducer => {
    switch (action.type) {
        case "SET_CARTS":
            return {
                ...state,
                carts: action.payload.carts,
                totalCount: action.payload.totalCount,
                totalAmount: action.payload.totalAmount
            }
        default:
            return state
    }
}

export const cartActions = {
    setCartsAC: (carts: Array<ICart>, totalAmount: number, totalCount: number) =>
        ({type: "SET_CARTS", payload: {carts, totalAmount, totalCount}} as const),
};
export const getCartsTC = (status: string): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        const carts = await cartAPI.getCart(status)
        dispatch(cartActions.setCartsAC(carts.data.cartDtos, carts.data.totalAmount, carts.data.totalCount))
    }, dispatch);
};
export const addItemToCartTC = (ItemPriceId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await cartAPI.addItemToCart(ItemPriceId)
        await dispatch(getCartsTC("CHOOSE"))
    }, dispatch);
};
export const changeCartItemCountTC = (cartId: number, quantity: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await cartAPI.updateCart(cartId, quantity)
        await dispatch(getCartsTC("CHOOSE"))
    }, dispatch);
};
export const deleteItemFromCartTC = (cartId: number): ThunkType => async dispatch => {
    await commonThunkCreator(async () => {
        await cartAPI.deleteItemFromCart(cartId)
        await dispatch(getCartsTC("CHOOSE"))
    }, dispatch);
};
