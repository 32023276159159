import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {BrowserRouter} from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorFallBackPage} from './components/errorsPages/errorBoudery/ErrorFallBackPage';

ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <ErrorBoundary
                        FallbackComponent={ErrorFallBackPage}
                >
                    <App/>
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>, document.getElementById('root')
);

reportWebVitals();
