import React from 'react';
import './AuthLinks.scss'
import {NavLink} from "react-router-dom";
import {MODAL_WINDOWS} from "../../../components/modalWindow/ModalWindow";

interface IProps {
    showModal: (value:MODAL_WINDOWS) => void
    location: any
    isAuth: boolean
}

export const AuthLinks = ({showModal,location, isAuth}: IProps) => {
    if(isAuth){
        return null
    }

    return (
            <>
                <NavLink
                        to={{pathname: '/login', state: { background: location }}}
                        onClick={() => showModal(MODAL_WINDOWS.LOGIN)}
                        className={'auth-link'}>
                    Войти
                </NavLink>
                <NavLink
                        to={{pathname: '/register', state: { background: location }}}
                        onClick={() => showModal(MODAL_WINDOWS.REGISTER)}
                        className={'auth-link'}>
                    Регистрация
                </NavLink>
            </>
    );
};
